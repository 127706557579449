import React, {useContext} from 'react';
import {Navbar, NavDropdown} from 'react-bootstrap';
import './protectedNavBar.css';
import {FaHome, FaUserAlt} from 'react-icons/fa';
import {useAuth0} from "@auth0/auth0-react";
import {Link, useRouteMatch} from 'react-router-dom';
import {AppContext} from "../../../App";
const ProtectedNavBar = (props: any) => {
    const {logout} = useAuth0();
    const {state,dispatch} = useContext(AppContext);

    const doLogout=()=>{
        dispatch({type:'update-verify', payload:{...state,isVerified:false}});
        logout({ returnTo: window.location.origin });
    }

    const {path} = useRouteMatch();

    return (
        <Navbar className={'protected-navbar'} fixed={'top'}>
            <Navbar.Brand className={'protected-nav-home'} as={Link} to={'/protected'}><FaHome className={'homeIcon'}/></Navbar.Brand>
            <Navbar.Brand className={'protected-navbar-brand'}>Virtual Help Group</Navbar.Brand>
            <NavDropdown id={'profile-drop'} title={<FaUserAlt/>} className={'protected-nav-dropdown'}>
                <NavDropdown.Item as={Link} to={`${path}/myprofile`}>Mi Perfil</NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown.Item onClick={doLogout}>Cerrar Sesión</NavDropdown.Item>
            </NavDropdown>
        </Navbar>
    );
}

export default ProtectedNavBar;
