import React, {useContext} from 'react';
import './sessions.display.css';
import {PlannerContext} from "../../planner";
import {Button, ListGroup} from 'react-bootstrap';
import {displayName} from "../../../../Utils/utilities";
import {MdModeEdit, MdRemoveRedEye} from 'react-icons/md';

const SessionDisplay = (props: any) => {
    const {state,dispatch} = useContext(PlannerContext);

    const viewSession = (index:number)=>{
        const session = state.currentSessions[index];
        dispatch({type:'launch-view-modal', payload:{...state,selectedSession: session}});
    }

    const editSession = (index:number)=>{
        const session = state.currentSessions[index];
        dispatch({type:'launch-edit-modal', payload:{...state,selectedSession: session}});
    }

    return (
        <div className={'session-display-container'}>
            <ListGroup>
                {state.currentSessions.map((session:any, index:number)=>{
                    const label = displayName(
                        session.therapist.user.firstName,
                        session.therapist.user.firstLastName
                    );
                    return <ListGroup.Item key={session.id} className={'session-display-item'}>
                        <div className={'session-display-text'}>
                            {label} {session.time}
                        </div>
                        <div className={'session-display-button-group'}>
                            <Button className={'session-display-buttons'}
                                    onClick={()=>editSession(index)}
                            >
                                <MdModeEdit/>
                            </Button>
                            <Button className={'session-display-buttons'}
                                    onClick={()=>viewSession(index)}
                            >
                                <MdRemoveRedEye/>
                            </Button>

                        </div>
                    </ListGroup.Item>
                })}
            </ListGroup>

        </div>
    );
}

export default SessionDisplay;
