import React, {useEffect, useState} from 'react';
import './editable.list.css';
import {Button, Form} from 'react-bootstrap';
import {RiAddCircleFill as IcoAdd, RiCloseCircleFill as IcoDel} from 'react-icons/ri';
import {ListOption} from "../DualList/dual.list";
import {Link} from "../../Utils/types";

export type EditableListProps = {
    DefaultItems:Link[];
    OnChange:(content:Link[])=>void;
}

const EditableList = (props: EditableListProps) => {
    const [state,setState] = useState([] as Link[]);
    const [options, setOptions] = useState([] as ListOption[]);
    const [error,setError] = useState({
        error:false,
        errorMessage:''
                                      });

    const {DefaultItems,OnChange} = props;
    const titleRef = React.createRef<HTMLInputElement>();
    const urlRef = React.createRef<HTMLInputElement>();
    const validUrl = /(^http[s]?:\/{2})|(^www)|(^\/{1,2})/igm;

    useEffect(()=>{
        const opts = DefaultItems.map((link)=>{
            return {value:link.id, label:`${link.title} | ${link.url}`} as ListOption;
        })
        setState(DefaultItems);
        setOptions(opts);
    }, [DefaultItems]);

    function deleteItem(value: string | number) {
        const newState = state.filter((opt)=>opt.id !== value);
        setState(newState);
        OnChange(newState);
    }

    function addItem(){
        const titleNode = titleRef.current;
        const urlNode = urlRef.current;

        if(titleNode === null || urlNode === null)
            return;

        if(titleNode.value === '' || titleNode.value === null)
        {
            setError({error:true, errorMessage: 'El titulo es necesario'});
            return;
        }

        if(urlNode.value === '' || urlNode.value === null){
            setError({error:true, errorMessage: 'El url es necesario'});
            return;
        }

        if(!validUrl.test(urlNode.value))
        {
            setError({error:true, errorMessage: 'El url no es valido'});
            return;
        }

        const newOpt = {value:0, label:`${titleNode.value} | ${urlNode.value}`};
        const newLink = {id:null, title:titleNode.value, url: urlNode.value};
        const newState = [...state,newLink] as Link[];
        const newOptions = [...options, newOpt];
        setState(newState);
        setOptions(newOptions);
        OnChange(newState);
        titleNode.value = '';
        urlNode.value = '';
        if(error.error){
            setError({error: false, errorMessage: ''});
        }
    }

    return (
        <div className={'editable-list-container'}>
            <div className={'editable-list-editor'}>
                <div className={'editable-list-title'}>
                    <Form.Label>Titulo</Form.Label>
                    <Form.Control type={'text'} ref={titleRef}/>
                </div>
                <div className={'editable-list-url'}>
                    <Form.Label>Url</Form.Label>
                    <Form.Control type={'text'} ref={urlRef}/>
                </div>
                <div className={'editable-list-buttons'}>
                    <Button onClick={addItem}><IcoAdd/></Button>
                </div>
            </div>
            {error.error &&
                <div className={'editable-list-error'}>
                    {error.errorMessage}
                </div>
            }
            <div className={'editable-list-item-container'}>
                {(options && options.length>0) &&
                    options.map((opt, index)=>{
                        return (<div className={'editable-list-items'}>
                            <div className={'editable-list-items-delete'}>
                                <Button variant={'danger'}
                                        onClick={()=>deleteItem(opt.value)}
                                >
                                    <IcoDel/>
                                </Button>
                            </div>
                            <div className={'editable-list-items-label'}>
                                <Form.Control type={'text'} defaultValue={opt.label} key={index} readOnly={true}/>
                            </div>
                        </div>);
                    }
                )}
            </div>
        </div>
    );
}

export default EditableList;
