import React, {useContext} from 'react';
import './month.control.css';
import {PlannerContext} from "../../planner";
import {FaArrowAltCircleLeft as ArrowLeft, FaArrowAltCircleRight as ArrowRight} from 'react-icons/fa';
import { Button } from 'react-bootstrap';

const MonthControl = (props: any) => {
    const {state, dispatch} = useContext(PlannerContext);
    const {month, year, week} = state;

    let monthName='';
    switch (month){
        case 1: monthName='Enero'; break;
        case 2: monthName='Febrero'; break;
        case 3: monthName='Marzo'; break;
        case 4: monthName='Abril'; break;
        case 5: monthName='Mayo'; break;
        case 6: monthName='Junio'; break;
        case 7: monthName='Julio'; break;
        case 8: monthName='Agosto'; break;
        case 9: monthName='Septiembre'; break;
        case 10: monthName='Octubre'; break;
        case 11: monthName='Noviembre'; break;
        case 12: monthName='Diciembre'; break;

    }

    const nextMonth=()=>{
        dispatch({type:'nextMonth',payload:{month,year,week}});
    }
    const prevMonth=()=>{
        dispatch({type:'previousMonth',payload:{month,year,week}});
    }
    const today=()=>{
        dispatch({type:'today',payload:{month,year,week}})
    }

    const showModal=()=>{
        dispatch({type:'launch-modal',payload:{...state, launchAddModal: true}});
    }

    return (
        <div className={'month-control-container'}>
            <div className={'month-changer'}>
                <div><ArrowLeft className={'arrow-icon'} onClick={prevMonth}/></div>
                <div className={'month-name'}>{monthName} - {year}</div>
                <div><ArrowRight className={'arrow-icon'} onClick={nextMonth}/></div>
            </div>
            <div>
                <Button onClick={today} className={'month-controller-buttons'}>Hoy</Button>
                <Button onClick={showModal} variant={'success'} className={'month-controller-buttons'}>+ Crear</Button>
            </div>
        </div>
    );
}

export default MonthControl;
