import React from 'react';
import {Image} from 'react-bootstrap'
import './user.list.view.css';

export type UserListViewProps = {
    profilePicture: string;
    userId: number;
    userName: string;
    clickHandler: (userId:number)=>void;
}

const UserListView = (props: UserListViewProps) => {
    const {profilePicture, userId, userName, clickHandler} = props;

    const raiseClick= ()=>{
        clickHandler(userId);
    }

    return (
        <div className={'user-list-view-container'} onClick={raiseClick}>
            <Image className={'user-list-view-image'} src={profilePicture} roundedCircle />
            <div>{userName}</div>
        </div>
    );
}

export default UserListView;
