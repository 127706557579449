import React, {useEffect, useReducer} from 'react';
import './planner.css';
import MonthView from "./MonthView/monthView";
import Control from "./Controll/control";
import {PlannerReducer} from "./planner.reducer";
import SessionModal from "./SessionModal/session.modal";
import SharedToast from "../../Shared/SharedToast/shared.toast";
import {Session} from "./SessionModal/session.modal.types";

export type PlannerState={
    day:number,
    month:number,
    year:number,
    week:number,
    providerId:number,
    currentSessions:any[],
    launchAddModal:boolean,
    modalEditMode: string|null,
    successToast:boolean,
    errorToast:boolean,
    successMessage:string,
    errorMessage:string,
    selectedSession:Session | null
}

const initialState:PlannerState = {
    day: 1,
    month: 1,
    year: 2020,
    week: 1,
    providerId:0,
    currentSessions:[],
    launchAddModal:false,
    modalEditMode:null,
    successToast:false,
    errorToast:false,
    successMessage:'',
    errorMessage:'',
    selectedSession: null
}

export const PlannerContext = React.createContext<{
    state: PlannerState;
    dispatch:React.Dispatch<any>;
}>({
    state:initialState,
    dispatch: ()=>null
});

const Planner = (props: any) => {
    const[state,dispatch] = useReducer(PlannerReducer, initialState);

    useEffect(()=> {
        dispatch({type: 'today', payload: {state}});
    },[]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleSuccessToastClose = ()=>{
        dispatch({type:'close-success-modal', payload:{state}});
    }

    const handleErrorToastClose = ()=>{
        dispatch({type:'close-error-modal', payload:{state}});
    }

    return (
        <PlannerContext.Provider value={{state,dispatch}}>
            <div className={'planner-container'}>
                <MonthView height={100} width={80}/>
                <Control />
                {state.launchAddModal &&
                    <SessionModal
                        editMode={state.modalEditMode}
                        session={state.selectedSession}/>}
            </div>
            <SharedToast
                handleClose={handleSuccessToastClose}
                show={state.successToast}
                message={state.successMessage}
                variant={'success'}
            />
            <SharedToast
                handleClose={handleErrorToastClose}
                show={state.errorToast}
                message={state.errorMessage}
                variant={'error'}
            />
        </PlannerContext.Provider>
    );
}

export default Planner;
