import { DateTime } from "luxon";
import {PlannerState} from "./planner";

export function PlannerReducer(state:PlannerState, action:any){
    const {day,month, year, week} = action.payload;
    let newMonth = month;
    let newYear = year;
    let newDay = day;
    switch (action.type){
        case 'update':
            return {...state, week,month,year};
        case 'nextMonth':
            if(newMonth===12){
                newMonth = 1;
                newYear++;
            }else{
                newMonth++;
            }
            return {...state,year:newYear,month:newMonth,currentSessions:[]};
        case 'previousMonth':
            if(newMonth===1){
                newMonth = 12;
                newYear--;
            }else{
                newMonth--;
            }
            return {...state,year:newYear,month:newMonth,currentSessions:[]};
        case 'today':
            let today = DateTime.local();
            newDay = today.day;
            newMonth = today.month;
            newYear = today.year;
            return {...state,year:newYear,month:newMonth, day:newDay};
        case 'update-provider':
            return {...state,providerId:action.payload.providerId};
        case 'show-sessions':
            return {...state,currentSessions:action.payload.currentSessions};
        case 'launch-modal':
            return {...state,launchAddModal:true, modalEditMode:'edit', selectedSession:null};
        case 'close-modal':
            return {...state,launchAddModal:false};
        case 'save-modal-success':
            return {...state,launchAddModal:false,successToast:true,successMessage:action.payload.successMessage};
        case 'save-modal-error':
            return {...state,launchAddModal:false,errorToast:true,errorMessage:action.payload.errorMessage};
        case 'close-success-modal':
            return {...state,successToast:false,successMessage:''};
        case 'close-error-modal':
            return {...state,errorToast:false,errorMessage:''};
        case 'launch-edit-modal':
            return {...state,launchAddModal:true, modalEditMode:'edit', selectedSession:action.payload.selectedSession};
        case 'launch-view-modal':
            return {...state,launchAddModal:true, modalEditMode:'view', selectedSession:action.payload.selectedSession};
        default:
            return state;
    }
}
