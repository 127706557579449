import React, {useContext} from 'react';
import './monthView.css';
import {ListGroup} from 'react-bootstrap';
import {displayName} from "../../../Utils/utilities";
import {PlannerContext} from "../planner";

//Max events 3
const MonthCell = (props: any) => {
    const {state,dispatch} = useContext(PlannerContext);
    const {date,disabled,sessions} = props;

    const handleClick = ()=>{
        if(disabled)
            return;
        dispatch({type:'show-sessions',payload:{...state,currentSessions: sessions}});
    }

    return (
        <div className={`month-cell ${disabled?'month-cell-disabled':''}`} onClick={handleClick} >
            <div className={'month-cell-date'}>{date}</div>
            <div className={'month-cell-session-containers'}>
                <ListGroup>
                    {sessions.length<=3 &&
                    sessions.map((session:any)=>{
                        const label = displayName(
                            session.therapist.user.firstName,
                            session.therapist.user.firstLastName
                        );
                        return <ListGroup.Item className={'session-badge'} key={session.id}>{label}</ListGroup.Item>
                    })
                    }
                    {sessions.length>3 &&
                    sessions.slice(0,3).map((session:any)=>{
                        const label = displayName(
                            session.therapist.user.firstName,
                            session.therapist.user.firstLastName
                        );
                        return <ListGroup.Item className={'session-badge'} key={session.id}>{label}</ListGroup.Item>
                    })
                    }
                </ListGroup>
            </div>
            {sessions.length>3 && <div className={'month-cell-show-more'}>{sessions.length-3} más</div>}
        </div>
    );
}

export default MonthCell;
