import React from 'react';
import GridMenu from "./GridMenu/gridMenu";

const Landing = (props: any) => {
    return (
        <GridMenu/>
    );
}

export default Landing;
