import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../App";
import axios from 'axios';
import ProviderGalleryCard from "./ProviderGalleryCard";
import {Col, Container, Row} from "react-bootstrap";
import Select from 'react-select';
import Loading from "../Shared/Loading";
import {LoadCategories, LoadSpecialities, LoadTherapistTypesPublic} from "../Utils/utilities";
import './ProviderGallery.css';

const ProviderGallery = (props: any) => {
    const [data, setData] = useState([]); //Local State
    const [options, setOptions] = useState({
        specialities:[],
        categories:[],
        languages:[],
        therapistTypes:[],
        categoryId:0,
        specialtyId:0,
        languageId:0,
        therapistTypeId:1
                                           });
    const [isLoading, setIsLoading] = useState(true);
    const {state} = useContext(AppContext);
    const {backendurl} = state;
    const baseUrl = props.baseUrl?props.baseUrl:'';
    const showCategory = props.showCategory;


    const filterProviders = async (categoryId:number,
                                   specialtyId:number,
                                   languageId:number,
                                   therapistType = 0):Promise<any[]> =>{
        const response = await axios.post(`${backendurl}/public/filteredProviderCards`,{
            categoryId,
            specialtyId,
            languageId,
            therapistType
        });
        return response.data;
    }

    const reloadOptions = async (typeId:number)=>{
        const specialities = await LoadSpecialities(backendurl,typeId);
        const categories = await LoadCategories(backendurl,typeId);

        let specialitiesOptions = specialities.map((speciality:any)=>{
            return {value:speciality.id, label:speciality.description};
        });

        specialitiesOptions = [{value:0, label:'Todos'},...specialitiesOptions];

        let categoriesOptions = categories.map((category:any)=>{
            return {value:category.id, label:category.description};
        });

        categoriesOptions = [{value:0, label:'Todos'},...categoriesOptions];
        const optionsData = {...options,
            specialities: specialitiesOptions as any,
            categories: categoriesOptions as any,
            therapistTypeId:typeId,
            categoryId: 0,
            specialtyId:0
        };
        setOptions(optionsData);
    }

    const getData = async () => {
        const specialities = await axios.get(`${backendurl}/public/specialities`);
        const categories = await axios.get(`${backendurl}/public/categories`);
        const languages = await axios.get(`${backendurl}/public/languages`);
        const types = await LoadTherapistTypesPublic(backendurl);

        const providers = await filterProviders(options.categoryId,options.specialtyId,options.languageId, options.therapistTypeId);

        let specialitiesOptions = specialities.data.map((speciality:any)=>{
            return {value:speciality.id, label:speciality.description};
        });

        specialitiesOptions = [{value:0, label:'Todos'},...specialitiesOptions];

        let categoriesOptions = categories.data.map((category:any)=>{
            return {value:category.id, label:category.description};
        });

        categoriesOptions = [{value:0, label:'Todos'},...categoriesOptions];

        let languagesOptions = languages.data.map((language:any)=>{
            return {value:language.id, label:language.description};
        });

        languagesOptions = [{value:0, label:'Todos'},...languagesOptions];

        let typesOptions = types.map((tt)=>{
            return {value:tt.id, label:tt.name};
        })

        const optionsData = {...options,specialities: specialitiesOptions, categories: categoriesOptions, languages: languagesOptions,
        therapistTypes:typesOptions as any};
        setOptions(optionsData);
        setData(providers as never[]);
        setIsLoading(false);
    }

    useEffect(()=>{
       getData();
    },[]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleTypesChanged = async (event:any)=>{
        const {value} = event;
        setOptions({...options, therapistTypeId: value});
        const providers = await filterProviders(0,0,options.languageId, value);
        setData(providers as never[]);
        await reloadOptions(value);
    }

    const handleSpecialitiesChanged = async (event:any)=>{
        const {value} = event;
        setOptions({...options, specialtyId: value});
        const providers = await filterProviders(options.categoryId,value,options.languageId, options.therapistTypeId);
        setData(providers as never[]);
    }

    const handleCategoriesChanged = async (event:any)=>{
        const {value} = event;
        setOptions({...options, categoryId: value});
        const providers = await filterProviders(value,options.specialtyId,options.languageId, options.therapistTypeId);
        setData(providers as never[]);
    }

    const handleLanguageChanged = async (event:any)=>{
        const {value} = event;
        setOptions({...options, languageId: value});
        const providers = await filterProviders(options.categoryId,options.specialtyId,value, options.therapistTypeId);
        setData(providers as never[]);
    }

    console.log('Show Category:',showCategory);
    console.log('Props:', props);
    return (
        <Container id={'terapeutas'} fluid style={{paddingLeft:'3rem', paddingRight:'3rem', paddingTop:'1rem'}}>
            {isLoading?(
                <Loading />
            ):(
                <div>
                    <div style={{marginBottom:'1rem'}}>
                        <Row>
                            <Col>
                                <h3 className={'provider-gallery-title'}>Servicio</h3>
                                <br/>
                                <Select options={options.therapistTypes}
                                        isLoading={isLoading}
                                        defaultValue={options.therapistTypes[0]}
                                        onChange={handleTypesChanged}
                                />
                            </Col>
                            <Col>
                                <h3 className={'provider-gallery-title'}>Especialidad</h3>
                                <br/>
                                <Select options={options.specialities}
                                        isLoading={isLoading}
                                        defaultValue={options.specialities[0]}
                                        onChange={handleSpecialitiesChanged}
                                />
                            </Col>
                            {showCategory && <Col>
                                <h3 className={'provider-gallery-title'}>Categorías</h3>
                                <br/>
                                <Select options={options.categories}
                                        isLoading={isLoading}
                                        defaultValue={options.categories[0]}
                                        onChange={handleCategoriesChanged}
                                />
                            </Col>}
                            <Col>
                                <h3 className={'provider-gallery-title'}>Idiomas</h3>
                                <br/>
                                <Select options={options.languages}
                                        isLoading={isLoading}
                                        defaultValue={options.languages[0]}
                                        onChange={handleLanguageChanged}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className={'d-flex flex-wrap justify-content-center'}>
                        {data.length > 0 ? (
                                data.map((card:any)=>{
                                    return <ProviderGalleryCard
                                        key={card.id}
                                        imgUrl={`${card.imgUrl}`}
                                        title={card.displayName}
                                        therapistId={card.id}
                                        baseUrl={baseUrl}
                                    />
                                })
                            ):
                            (
                                <h3>No hay coincidencias</h3>
                            )
                            }
                    </div>
                </div>
            )
            }
        </Container>
    );
}

export default ProviderGallery;
