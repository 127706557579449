import React, {useEffect, useState} from 'react';
import Select from "react-select";

const DurationPicker = (props: any) => {

    const {selected,editMode} = props;
    const [state, setState]= useState({
        selected:{value:0, label:''}
                                      })

    const durations = [
        {value:30, label:'30 minutos'},
        {value:45, label:'45 minutos'},
        {value:60, label:'60 minutos'},
    ]

    const handleChange = (event:any)=>{
        props.changeHandler(event);
        setState({...state,selected: event});
    }

    useEffect(()=>{
        if(selected !== 0){
            const found = durations.filter(x=> x.value === parseInt(selected));
            if(found && found.length>0)
                setState({...state, selected:found[0]});
        }
    },[selected]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Select options={durations}
                    value={state.selected}
                    onChange={handleChange}
                    isDisabled={editMode}
                    placeholder={'Seleccione una duración'}
            />
        </div>
    );
}

export default DurationPicker;
