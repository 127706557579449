import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';

const LandingHero = (props: any) => {
    return (
        <header className={'hero1'}>
            <Container className={'align-item-center'} >
                <Row>
                    <Col>
                        <h1>Psicólogos en línea</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>- Desde la comodidad de su casa u oficina -</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>

                    </Col>
                </Row>
            </Container>
        </header>
    );
}

export default LandingHero;
