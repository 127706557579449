import {AppState} from "./App";

export function Reducer(state:AppState, action:any){
    switch (action.type){
        case 'update-verify':
            return {...state,...action.payload};
        case 'update-userId':
            return {...state,userId:action.payload.userId};
        default:
            return state;
    }
}
