import React from 'react';
import {BiHourglass, BiLineChart, BiTone, BiCalendar, BiChair, BiCertification} from 'react-icons/bi';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const LandingEmpresas = (props: any) => {
    return (
        <div id={'empresas'} className={'lnd-sec-empresas-container'}>
            <div>
                <h3>Empresas</h3>
            </div>
            <div className={'lnd-sec-accordions'}>
                <Accordion className={'lnd-sec-acc'} allowZeroExpanded={true} >
                    <AccordionItem>
                        <AccordionItemHeading className={'lnd-sec-acc-heading'}>
                            <AccordionItemButton className={'lnd-sec-acc-button'}>
                                <BiHourglass className={'lnd-sec-acc-icon'}/>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className={'lnd-sec-acc-content'}>
                            Reduce el absentismo laboral
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                <Accordion className={'lnd-sec-acc'} allowZeroExpanded={true} >
                    <AccordionItem>
                        <AccordionItemHeading className={'lnd-sec-acc-heading'}>
                            <AccordionItemButton className={'lnd-sec-acc-button'}>
                                <BiLineChart className={'lnd-sec-acc-icon'}/>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className={'lnd-sec-acc-content'}>
                            Aumenta satisfacción de sus empleados
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                <Accordion className={'lnd-sec-acc'} allowZeroExpanded={true} >
                    <AccordionItem>
                        <AccordionItemHeading className={'lnd-sec-acc-heading'}>
                            <AccordionItemButton className={'lnd-sec-acc-button'}>
                                <BiTone className={'lnd-sec-acc-icon'}/>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className={'lnd-sec-acc-content'}>
                            Brinda un beneficio diferenciador para los empleados, un beneficio único que no encontraran en ningún otro lugar.
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                <Accordion className={'lnd-sec-acc'} allowZeroExpanded={true} >
                    <AccordionItem>
                        <AccordionItemHeading className={'lnd-sec-acc-heading'}>
                            <AccordionItemButton className={'lnd-sec-acc-button'}>
                                <BiCalendar className={'lnd-sec-acc-icon'}/>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className={'lnd-sec-acc-content'}>
                            Consultas psicológicas disponibles los 365 días del año.
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                <Accordion className={'lnd-sec-acc'} allowZeroExpanded={true} >
                    <AccordionItem>
                        <AccordionItemHeading className={'lnd-sec-acc-heading'}>
                            <AccordionItemButton className={'lnd-sec-acc-button'}>
                                <BiChair className={'lnd-sec-acc-icon'}/>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className={'lnd-sec-acc-content'}>
                            Disminución de costos e incomodidades por traslados a clínica de atención psicológica.
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                <Accordion className={'lnd-sec-acc'} allowZeroExpanded={true} >
                    <AccordionItem>
                        <AccordionItemHeading className={'lnd-sec-acc-heading'}>
                            <AccordionItemButton className={'lnd-sec-acc-button'}>
                                <BiCertification className={'lnd-sec-acc-icon'}/>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className={'lnd-sec-acc-content'}>
                            Atención psicológica de calidad por profesionales certificados.
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
}

export default LandingEmpresas;
