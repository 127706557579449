import React from 'react';
import './LandingFooter.css';
import {FaFacebookMessenger as FbMsg, FaPhoneAlt, FaRegEnvelope} from 'react-icons/fa';
import { GrFacebook, GrInstagram, GrYoutube } from 'react-icons/gr';
function LandingFooter(props: any) {
    return (
        <div id={'contactos'} className={'landing-footer-container'}>
            <div className={'lf-title-container'}>
                <h2 className={'lf-title'}>Virtual Help</h2>
            </div>
            <div className={'lf-contact-social-container'}>
                <div className={'lf-contact-phone-email-container'}>
                    <div>
                        <FaPhoneAlt className={'lf-icons'}/><a href={'tel:+504 8883-5420'}>+504 8883-5420</a><strong> / </strong><a href={'tel:+504 2209-4897'}>+504 2209-4897</a>
                    </div>
                    <div>
                        <FaRegEnvelope className={'lf-icons'}/><a href="mailto:contacto@virtualhelpgroup.com">contacto@virtualhelpgroup.com</a>
                    </div>
                </div>
                <div>
                    <a href="https://www.youtube.com/channel/UCBCPbvtrXs142LJHaFD0jmA?view_as=subscriber">
                        <GrYoutube className={'lf-icons'}/>
                    </a>
                    <a href="https://www.instagram.com/virtualhelpgroup/?hl=es">
                        <GrInstagram className={'lf-icons'}/>
                    </a>
                    <a href="https://www.facebook.com/virtualhelpgroup/">
                        <GrFacebook className={'lf-icons'}/>
                    </a>
                    <a href={'http://m.me/virtualhelpgroup'}>
                        <FbMsg className={'lf-icons'}/>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default LandingFooter;
