import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import {AppContext} from "../../../../../App";
import Select from "react-select";
import {useAuth0} from "@auth0/auth0-react";
import {displayName} from "../../../../Utils/utilities";
import './client.picker.css';

const ClientPicker = (props: any) => {
    const [data, setData] = useState([]); //Local State
    const {state} = useContext(AppContext);
    const [pickerState,setPickerState] = useState({
        selected:{value:0,label:''}
                                                  });
    const {backendurl} = state;
    const {getAccessTokenSilently} = useAuth0();
    const {isDisabled, selectedValue} = props;

    const getData = async ()=>{
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${backendurl}/user/clients`,
                                         {
                                             headers:{
                                                 Authorization:`Bearer ${token}`
                                             }
                                         });
        setData(response.data);
    }

    useEffect(()=>{
        const invoke = async ():Promise<void>=>{
            await getData();
        };
        invoke().then(r => {});
    },[]);// eslint-disable-line react-hooks/exhaustive-deps

    const clientOptions = data.map((client:any)=>{
        const id = client.id;
        const label = displayName(client.firstName, client.firstLastName);
        return {value:id,label};
    });

    const handleClientChange = (event:any)=>{
        props.changeHandler(event);
        setPickerState({...pickerState,selected: event});
    }

    useEffect(()=>{
        if(selectedValue != null && clientOptions && clientOptions.length > 0){
            const found = clientOptions.filter(x=>x.value === selectedValue);
            if(found && found.length>0)
                setPickerState({...pickerState,selected: found[0]});
        }
    },[data]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Select options={clientOptions}
                    value={pickerState.selected}
                    onChange={handleClientChange}
                    isDisabled={isDisabled}
                    placeholder={'Seleccione un Cliente'}
            />
        </div>
    );
}

export default ClientPicker;
