import React, {useEffect, useState} from 'react';
import './dual.list.css';
import {Button, Form} from 'react-bootstrap';
import {
    CgChevronRightO,
    CgChevronLeftO,
    CgChevronDoubleLeftO,
    CgChevronDoubleRightO
} from 'react-icons/cg';
import {isNumber} from "../../Utils/utilities";

export type ListOption = {
    value:string|number;
    label:string;
}

export type DualListSource = {
    source: ListOption[];
    target: ListOption[];
}

const DualList = (props: any) => {
    const [state, setState] = useState({
        source:[],
        target:[]} as DualListSource);
    const [sourceSelected, setSourceSelected] = useState([] as ListOption[]);
    const [targetSelected, setTargetSelected] = useState([] as ListOption[]);
    const onChangeCallback = props.onChange;
    const SourceOptions = props.SourceOptions;

    useEffect(()=>{
        if(SourceOptions){
            const options = SourceOptions as ListOption[];
            if(props.SelectedValues && props.SelectedValues.length > 0){
                const startOptions = props.SelectedValues as ListOption[];
                const newSource = options.filter((item)=>{
                    const found = startOptions.find(x=> x.value === item.value);
                    return !found;
                });
                const newTarget = [...startOptions];
                setState({...state,source:newSource,target:newTarget});
            }else{
                setState({...state,source:options});
            }
        }
    },[SourceOptions]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleSourceChange = (event:any)=>{
        const {target} = event;
        const options = target.selectedOptions as HTMLOptionsCollection;
        const selectedOptions = [] as ListOption[];
        for (let i = 0; i < options.length; i++) {
            const opt = options.item(i);
            if(opt){
                if(isNumber(opt.value))
                    selectedOptions.push({value:Number(opt.value), label:opt.label});
                else
                    selectedOptions.push({value:opt.value, label:opt.label});
            }

        }
        setSourceSelected(selectedOptions);
    }

    const handleTargetChange = (event:any)=>{
        const {target} = event;
        const options = target.selectedOptions as HTMLOptionsCollection;
        const selectedOptions = [] as ListOption[];
        for (let i = 0; i < options.length; i++) {
            const opt = options.item(i);
            if(opt){
                if(isNumber(opt.value))
                    selectedOptions.push({value:Number(opt.value), label:opt.label});
                else
                    selectedOptions.push({value:opt.value, label:opt.label});
            }

        }
        setTargetSelected(selectedOptions);
    }

    const handleMoveTarget = ()=>{
        if(sourceSelected.length <= 0)
            return;
        const newSource = state.source.filter((item)=>{
            const found = sourceSelected.find(x=> x.value === item.value);
            return !found;
        });
        const newTarget = [...state.target,...sourceSelected];
        setState({...state,source:newSource,target:newTarget});
        setTargetSelected([]);
        setSourceSelected([]);
        if(onChangeCallback) onChangeCallback(newTarget);
    }

    const handleMoveAllTarget = ()=>{
        const newSource = [] as ListOption[];
        const newTarget = [...state.target,...state.source];
        setState({...state,source:newSource,target:newTarget});
        setTargetSelected([]);
        setSourceSelected([]);
        if(onChangeCallback) onChangeCallback(newTarget);
    }

    const handleMoveSource = ()=>{
        if(targetSelected.length <= 0)
            return;
        const newTarget = state.target.filter((item)=>{
            const found = targetSelected.find(x=> x.value === item.value);
            return !found;
        });
        const newSource = [...state.source,...targetSelected];
        setState({...state,source:newSource,target:newTarget});
        setTargetSelected([]);
        setSourceSelected([]);
        if(onChangeCallback) onChangeCallback(newTarget);
    }

    const handleMoveAllSource = ()=>{
        const newSource = [...state.source,...state.target];
        const newTarget = [] as ListOption[];
        setState({...state,source:newSource,target:newTarget});
        setTargetSelected([]);
        setSourceSelected([]);
        if(onChangeCallback) onChangeCallback(newTarget);
    }

    return (
        <div className={'dual-list-container'}>
            <div className={'source-list-container'}>
                <Form.Control as={'select'}
                              multiple
                              className={'source-list'}
                              onChange={handleSourceChange}
                >
                    {state.source.length>0 &&
                        state.source.map((option:ListOption)=>{
                            return <option
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </option>
                        })
                    }
                </Form.Control>
            </div>
            <div className={'dual-list-control-container'}>
                <Button onClick={handleMoveAllTarget}>
                    <CgChevronDoubleRightO/>
                </Button>
                <Button onClick={handleMoveTarget}>
                    <CgChevronRightO/>
                </Button>
                <Button onClick={handleMoveSource}>
                    <CgChevronLeftO/>
                </Button>
                <Button onClick={handleMoveAllSource}>
                    <CgChevronDoubleLeftO/>
                </Button>
            </div>
            <div className={'target-list-container'}>
                <Form.Control as={'select'}
                              multiple
                              className={'target-list'}
                              onChange={handleTargetChange}
                >
                    {state.target.length>0 &&
                    state.target.map((option:ListOption)=>{
                        return <option
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    })
                    }
                </Form.Control>
            </div>
        </div>
    );
}

export default DualList;
