import React from 'react';
import './control.css';
import MonthControl from "./MonthControl/month.control";
import ProviderPicker from "./ProviderPicker/provider.picker";
import SessionDisplay from "./SessionsDisplay/session.display";

const Control = (props: any) => {
    return (
        <div className={'session-control-container'}>
            <MonthControl />
            <ProviderPicker/>
            <SessionDisplay/>
        </div>
    );
}

export default Control;
