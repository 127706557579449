import React from 'react';
import './GridCell.css';


const GridCell = (props: any) => {
    return (
        <div style={{ width:'100%',height:'100%', margin:0}}>
            {props.imgUrl?(
                <div>
                    <img className={'grid-cell-image'} src={props.imgUrl}/>
                </div>
            ):(
                <div style={
                    {
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        flexDirection:'column',
                        height:'100%',
                        padding:'1rem'
                    }
                }>
                    <h3>{props.title}</h3>
                    <br/>
                    <p>{props.children}</p>
                </div>
            )}

        </div>
    );
}

export default GridCell;
