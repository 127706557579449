import React, {useContext, useEffect, useState} from 'react';
import './monthView.css';
import MonthCell from "./monthCell";
import {PlannerContext} from "../planner";
import {DateTime} from "luxon";
import {AppContext} from "../../../../App";
import axios from "axios";
import { useAuth0 } from '@auth0/auth0-react';



const MonthView = (props: any) => {
    const {state} = useContext(PlannerContext);
    const {month, year, providerId} = state;
    const {state:appState} = useContext(AppContext);
    const {getAccessTokenSilently} = useAuth0();
    const {backendurl} = appState;
    const [sessions,setSessions] = useState([]);

    const {height, width} = props;
    const cells = [];

    let disabled = false;
    let firstDay = DateTime.local(year,month,1);
    let startDate = firstDay;
    let maxCells = 35;

    const getSessions = async ()=>{
        try{
            const lastDay = firstDay.endOf('month');
            const token = await getAccessTokenSilently();
            const response = await axios.get(`${backendurl}/therapy/session/byrange/${providerId}/${firstDay.toSQLDate()}/${lastDay.toSQLDate()}`,
                                             {
                                                 headers:{
                                                     Authorization:`Bearer ${token}`
                                                 }
                                             });
            //console.log(response.data);
            setSessions(response.data);
        }catch (err){
            console.log(err);
        }

    }

    useEffect(()=>{
        getSessions().then(r => {});
    },[state]); // eslint-disable-line react-hooks/exhaustive-deps

    if(firstDay.weekday !== 7)
        startDate = firstDay.minus({days:firstDay.weekday});

    if(firstDay.weekday === 5 || firstDay.weekday === 6)
        maxCells = 42;

    for(let i=0; i<maxCells; i++){
        if(startDate.month !== firstDay.month)
            disabled = true;
        else
            disabled = false;

        const dateSessions = sessions.filter((session:any)=> session.date === startDate.toSQLDate()); // eslint-disable-line no-loop-func
        cells.push(<MonthCell key={i+1} date={startDate.day} disabled={disabled} sessions={dateSessions}/>);
        startDate = startDate.plus({days:1});
    }

    return (
        <div className={'month-view-container'} style={{height:`${height}%`,width:`${width}%`}}>
            <div className={'month-day-week'}>
                <div className={'month-header'}>DOM</div>
                <div className={'month-header'}>LUN</div>
                <div className={'month-header'}>MAR</div>
                <div className={'month-header'}>MIE</div>
                <div className={'month-header'}>JUE</div>
                <div className={'month-header'}>VIE</div>
                <div className={'month-header'}>SAB</div>
            </div>
            <div className={'month-container'}>
                {cells}
            </div>
        </div>
    );
}

export default MonthView;
