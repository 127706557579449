import React from 'react';
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";


const Auth0ProviderWithHistory = ({children}:any) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN||'virtualhelpgroup.us.auth0.com';
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID||'v32S3CHZ2gSb4QkNKpsLbQ513LhFjW3h';
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE||'https://vhg-back-end';
    const history = useHistory();

    const onRedirectCallback = (appState:any) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
        >
            {children}
        </Auth0Provider>
    );
}

export default Auth0ProviderWithHistory;
