import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import GridCell from "./GridCell";
import imgS5 from '../../Assets/Servicios5.png';
import imgS6 from '../../Assets/Servicios6.png';
import imgS7 from '../../Assets/Servicios7.png';
import imgS8 from '../../Assets/Servicios8.png';
import imgS9 from '../../Assets/Servicios9.png';

const LandingMasServicio = (props: any) => {
    return (
        <Container id={'mas-servicios'} fluid style={{paddingLeft:'3rem', paddingRight:'3rem', paddingTop:'1rem'}}>
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <h2 style={{textAlign:'center'}}>Más de Nuestros Servicios</h2>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row>
                <Col sm={4} className={'gridCol'}>
                    <GridCell imgUrl={imgS5}/>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell title={'Consejería Familiar'}>
                        Terapias orientadas a ayudar a los miembros de la familia a mejorar la comunicación
                        y resolver los conflictos.
                    </GridCell>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell imgUrl={imgS6}/>
                </Col>
            </Row>

            <Row>
                <Col sm={4} className={'gridCol'}>
                    <GridCell title={'Terapia Grupal'}>
                        Proporciona a los pacientes sesiones de manera grupal, para mejorar su salud y
                        calidad de vida. Procura dotar a sus miembros de estrategias adecuadas de afrontación.
                    </GridCell>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell imgUrl={imgS7}/>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell title={'Orientación Educativa'}>
                        Servicios destinados a los alumnos, los padres y profesores,
                        con el objetivo de contribuir al desarrollo de sus tareas dentro del ámbito específico de los centros escolares.
                    </GridCell>
                </Col>
            </Row>
            <Row>
                <Col sm={4} className={'gridCol'}>
                    <GridCell imgUrl={imgS8}/>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell title={'Asesoramiento Profesional'}>
                        Orientado de conocerse a sí mismo a través de un enfoque proactivo para el
                        desarrollo profesional y personal en que decidir´ conscientemente qué clase de
                        persona quiere llegara ser.
                    </GridCell>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell imgUrl={imgS9}/>
                </Col>
            </Row>
        </Container>
    );
}

export default LandingMasServicio;
