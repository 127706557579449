import React, {useContext, useEffect, useState} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {AppContext} from "../../App";
import axios from 'axios';
import {Container, Row, Col, Badge} from "react-bootstrap";
import Loading from "../Shared/Loading";
import ReactPlayer from "react-player";
import TherapistPills from "./TherapistPills";
import {TiArrowBack} from 'react-icons/ti';
import './therapist.public.page.css';
import {Link} from "../Utils/types";
import {IoLogoWhatsapp as WhatsappIco} from 'react-icons/io';

const TherapistPublicPage = (props: any) => {
    const [therapist,setTherapist] = useState({} as any);
    const [isLoading,setIsLoading] = useState(true);
    const [specialities,setSpecialities] = useState([[],[],[],[]]);
    const [categories,setCategories] = useState([[],[],[],[]]);
    const {state} = useContext(AppContext);
    const {backendurl} = state;
    const {id} = useParams();
    const history = useHistory();

    const {user} = therapist;

    const fullName=()=>{
        return `${user.firstName}${user.middleName?` ${user.middleName}`:''}\
         ${user.firstLastName}${user.secondLastName?` ${user.secondLastName}`:''}`;
    }

    const chunkArray=(array:any[],chunkSize:number):any[]=>{
        console.log(`array: ${array.length}`);
        let results=[];
        while(array.length){
            results.push(array.splice(0,chunkSize));
        }
        return results;
    }



    useEffect(()=>{
        const loadData = async ()=>{
            const data = await axios.get(`${backendurl}/public/therapist/${id}`);
            data.data.biography = data.data.biography.replaceAll("\\n\\r","");
            data.data.biography = data.data.biography.replaceAll("\\n","");
            data.data.biography = data.data.biography.replaceAll("\\r","");
            data.data.introduction = data.data.introduction.replaceAll("\\n\\r","");
            data.data.introduction = data.data.introduction.replaceAll("\\n","");
            data.data.introduction = data.data.introduction.replaceAll("\\r","");
            setTherapist(data.data as any);
            const specItems = data.data.specialities;
            const catItems = data.data.categories;
            setSpecialities(chunkArray(specItems,Math.ceil((specItems.length/4))));
            setCategories(chunkArray(catItems,Math.ceil((catItems.length/4))));
            setIsLoading(false);
        }
        loadData();
    },[backendurl,id])

    const vpWidth = window.innerWidth;
    const vpHeigth = window.innerHeight;
    const youTubeWidth = vpWidth * 0.8;
    const youTubeHeight = vpHeigth * 0.8;

    return (
        <div>
            <div className={'menu-bar-bg'}></div>
            {!isLoading &&
            <div>
                <div className={'pic-intro-bio'}>
                    <div className={'pic-name'}>
                        <img alt={'foto de perfil'} src={`${user.urlProfilePicture}`}
                             style={{
                                 display:'block',
                                 marginTop:'3rem',
                                 width:'20rem',
                                 height:'20rem',
                                 marginLeft:'auto',
                                 marginRight:'auto'
                             }}
                        />
                        <h2>{fullName()}</h2>
                        <br/>
                        <div>
                            <a href={'https://wa.link/8xc3g1'} className={'tpp-hacer-cita'}>
                                <WhatsappIco/>Hacer Cita
                            </a>
                        </div>
                        <br/>
                        <div>
                            <TiArrowBack className={'therapist-public-goback'} onClick={()=>history.goBack()}/>
                        </div>
                    </div>
                    <div className={'intro-bio-container'}>
                        <div className={'intro-bio'}>
                            <h4>Introducción</h4>
                            <p>
                                {therapist.introduction}
                            </p>
                        </div>
                        <div className={'intro-bio'}>
                            <h4>Biografía</h4>
                            <p>
                                {therapist.biography}
                            </p>
                        </div>
                    </div>
                </div>

                <div className={'pills-container'}>
                    <TherapistPills items={specialities} title={'Especialidades'}/>
                </div>
                <div className={'pills-container'}>
                    <TherapistPills items={categories} title={'Servicios'}/>
                </div>
                <div className={'links-container'} >
                    <h4>Enlaces</h4>
                    <div className={'therapist-public-page-link-container'}>
                        {(therapist.links && therapist.links.length>0) &&
                        therapist.links.map((link:Link)=>{
                            return <a href={link.url} target={'_blank'} rel={'noopener noreferrer'}>
                                <Badge
                                    key={link.id}
                                    variant={"info"}
                                    className={'therapist-public-page-link-badge'}
                                >{link.title}</Badge>
                            </a>;
                        })
                        }
                    </div>
                </div>
                {therapist.youTubeUrl && therapist.youTubeUrl.length > 0 &&
                <div className={'video-container'}>
                    <ReactPlayer url={therapist.youTubeUrl} className={'profile-youtube-player'}/>
                </div>
                }
            </div>
            }
            {isLoading &&
                <Loading />
            }
        </div>
    );
}

export default TherapistPublicPage;
