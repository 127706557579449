import React from 'react';
import ProviderGallery from "../../User/ProviderGallery";

const TherapistSearch = (props: any) => {
    return (
        <div>
            <ProviderGallery baseUrl={'/protected'} showCategory={true}/>
        </div>
    );
}

export default TherapistSearch;
