import React, {useReducer} from 'react';
import './App.css';
import {Switch, Route} from 'react-router-dom';
import Landing from "./Components/Landing/Landing";
import ProtectedLanding from "./Components/Protected/ProtectedLanding";
import {Reducer} from './AppReducer';
import Login from "./Components/Login";
import Privacy from "./Components/privacy";
import Session from "./Components/Session/Session";

export type AppState = {
    backendurl:string,
    auth0Domain:string,
    isVerified:boolean,
    userId:number
}

const initialState:AppState = {
    backendurl:process.env.REACT_APP_BACKEND_URL?process.env.REACT_APP_BACKEND_URL:'https://virtualhelpgroup.com:25871',
    auth0Domain:process.env.REACT_APP_AUTH0_DOMAIN?process.env.REACT_APP_AUTH0_DOMAIN:'virtualhelpgroup.us.auth0.com',
    isVerified:false,
    userId:0
};

export const AppContext = React.createContext<{
    state:AppState;
    dispatch: React.Dispatch<any>;
}>({
       state: initialState,
       dispatch: ()=> null
   });

function App() {
    const [state, dispatch] = useReducer(Reducer,initialState);
    let routes = (
      <Switch>
          <Route path={"/protected"}>
              <ProtectedLanding/>
          </Route>
          <Route path={"/login"}>
              <Login/>
          </Route>
          <Route path={"/privacy"}>
              <Privacy/>
          </Route>
          <Route path={"/session"}>
              <Session/>
          </Route>
          <Route path={"/"}>
              <Landing/>
          </Route>

      </Switch>
    );

  return (
      <AppContext.Provider value={{state, dispatch}}>
          <div>
              {routes}
          </div>
      </AppContext.Provider>
  );
}

export default App;
