import {UserSource} from "./UserListing/user.listing";

export type UserManagerState = {
    users:UserSource[];
    selectedUser:UserSource|null;
    addingUser:boolean;
    editingUser:boolean;
    editUserId:number;
}

export function UserManagerReducer(state:UserManagerState, action:any):UserManagerState{
    const payload = action.payload;
    switch (action.type){
        case 'load-users':
            return {...state, users:payload.users};
        case 'select-user':
            return {...state, selectedUser:payload.selectedUser, addingUser:false, editUserId:0, editingUser:false};
        case 'add-user':
            return {...state, addingUser:payload.addingUser, editUserId:0, editingUser:false};
        case 'add-user-done':
            return {...state, addingUser:false,editUserId:0, editingUser:false};
        case 'edit-user':
            return {...state, editingUser:payload.editingUser, editUserId:payload.editUserId};
        default:
            return state;
    }
}
