import React, {useContext, useEffect} from 'react';
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../Shared/Loading";
import ProtectedNavBar from "./NavBar/protectedNavBar";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Landing from "./Landing";
import Footer from "./Footer/footer";
import TherapistSearch from "./TherapistSearch/therapistSearch";
import './protected.css';
import TherapistPublicPage from "../Public/TherapistPublicPage";
import Planner from "./Planner/planner";
import axios from "axios";
import {AppContext} from "../../App";
import MyProfile from "./MyProfile/my.profile";
import UserManager from "./UserManager/user.manager";
import Catalogs from "./Catalogs/catalogs";

const ProtectedLanding = (props: any) => {
    const {user, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const {state,dispatch} = useContext(AppContext);
    const {backendurl} = state;

    useEffect(()=>{
        const doVerify = async ()=>{
            if(isAuthenticated && !state.isVerified){
                const token = await getAccessTokenSilently();
                const response = await axios.post(`${backendurl}/user/verifyCreate`,
                                                  user,
                                                  {
                                                      headers:{
                                                          Authorization:`Bearer ${token}`
                                                      }
                                                  });
                const verified = response.data !== null;
                await dispatch({type:'update-verify', payload:{...state, isVerified: verified, userId: response.data.id}});
            }
        }
        doVerify();
    },[isAuthenticated,state,getAccessTokenSilently,user,dispatch,backendurl]);

    const {path} = useRouteMatch();
    return (
        <div className={'protected-container'}>
            <div className={'protected-header'}>
                <ProtectedNavBar/>
            </div>
            <div className={'protected-section'}>
                <Switch>
                    <Route path={`${path}/therapist/:id`}>
                        <TherapistPublicPage/>
                    </Route>
                    <Route path={`${path}/search`}>
                        <TherapistSearch/>
                    </Route>
                    <Route path={`${path}/planner`}>
                        <Planner />
                    </Route>
                    <Route path={`${path}/myprofile`}>
                        <MyProfile otherUserId={null} profileSaved={()=>{}} />
                    </Route>
                    <Route path={`${path}/user-manager`}>
                        <UserManager />
                    </Route>
                    <Route path={`${path}/catalogs`}>
                        <Catalogs />
                    </Route>
                    <Route path={`${path}`}>
                        <Landing/>
                    </Route>
                </Switch>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    );
}

export default withAuthenticationRequired(ProtectedLanding,{onRedirecting: ()=> <Loading/>});
