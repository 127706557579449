import React, {useEffect, useState} from 'react';
import {Nav, Navbar} from "react-bootstrap";
import {FaUserCircle as IcoUsuario} from 'react-icons/fa';
import './LandingNavBar.module.css';
import WebFont from "webfontloader";
const initialState = {
    bgColor:'transparent',
    brandColor:'white',
    navLinkColor:'white',
    togglerClass:'LandingNavBar-light-toggler'
}

const LandingNavBar = (props: any) => {
    const [state,setState] = useState(initialState);
    const {bgColor, brandColor, navLinkColor, togglerClass} = state;

    useEffect(()=>{
        WebFont.load({
                         google:{
                             families:['Aleo']
                         }
                     })
    },[]);

    const listenScroll = function () {
        if(window.scrollY > 50)
            setState({
                         bgColor: 'linear-gradient(180deg, #FFFFFF 0%, #D4D4D4 55%)',
                         brandColor: 'black',
                         navLinkColor: 'black',
                         togglerClass: 'LandingNavBar-dark-toggler'
            });
        else
            if(!props.useDark)
                setState(initialState);
    }

    useEffect(()=>{
        if(props.useDark){
            setState({
                         bgColor: 'linear-gradient(180deg, #FFFFFF 0%, #D4D4D4 55%)',
                         brandColor: 'black',
                         navLinkColor: 'black',
                         togglerClass: 'LandingNavBar-dark-toggler'});
        }
        window.addEventListener("scroll", listenScroll);
    },[props.useDark]);// eslint-disable-line react-hooks/exhaustive-deps

    // @ts-ignore
    return (
        <Navbar expand={'lg'} fixed={'top'} style={{animation:'false', background:bgColor}}>
            <Navbar.Brand href={'/#page-top'} style={{color:brandColor, fontFamily:'Aleo'}}>Virtual Help</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className={togglerClass} />
            <Navbar.Collapse id="basic-navbar-nav" style={{alignSelf:'flex-end', justifyContent:'flex-end', animation:'false'}} >
                <Nav style={{fontWeight:'bold'}}>
                    <Nav.Link href={"/#servicios"} style={{color:navLinkColor}}>SERVICIOS</Nav.Link>
                    <Nav.Link href={"/#terapeutas"} style={{color:navLinkColor}}>TERAPEUTAS</Nav.Link>
                    <Nav.Link href={"/#empresas"} style={{color:navLinkColor}}>EMPRESAS</Nav.Link>
                    <Nav.Link href={"/#quienes-somos"} style={{color:navLinkColor}}>QUIENES SOMOS</Nav.Link>
                    <Nav.Link href={"/#contactos"} style={{color:navLinkColor}}>CONTACTO</Nav.Link>
                    <Nav.Link href={"/login"} style={{color:navLinkColor}}>
                        <IcoUsuario style={{color:'#007BFF'}}/> INICIAR SESIÓN
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default LandingNavBar;
