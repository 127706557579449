import React from 'react';
import './footer.css';

const Footer = (props: any) => {
    return (
        <div className={'protectedFooter'}>
            © {(new Date()).getFullYear()} | Derechos Reservados
        </div>
    );
}

export default Footer;
