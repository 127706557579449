import React, {ChangeEvent, useState} from 'react';
import './user.listing.css';
import UserListView from "./UserListView/user.list.view";

export type UserSource = {
    profilePicture: string,
    userId: number,
    userName: string,
}

export type UserListingProps = {
    userSource: UserSource[],
    clickHandler: (userId:number) => void,
    onUserTypeChanged: (userType:string)=>void
    onAddUserClicked: ()=>void;
}

const UserListing = (props: UserListingProps) => {
    const {userSource, clickHandler, onUserTypeChanged, onAddUserClicked} = props;
    const [userType, setUserType] = useState('therapist');

    const userSelected = (userId:number)=>{
        clickHandler(userId);
    }

    const onTypeChanged = (event:ChangeEvent<HTMLInputElement>)=>{
        setUserType(event.target.value);
        onUserTypeChanged(event.target.value);
    }

    return (
        <div className={'user-listing-container'}>
            <div className={'user-listing-type-selector'}>
                <label>
                    <input type="radio" value={'therapist'} checked={userType==='therapist'} onChange={onTypeChanged} />Terapeutas
                </label>
                <label>
                    <input type="radio" value={'clients'} checked={userType==='clients'} onChange={onTypeChanged}/>Clientes
                </label>
                <button className={'user-listing-add-user'} onClick={onAddUserClicked}>+</button>
            </div>
            {userSource &&
                userSource.map((userInfo, index)=>{
                    return <UserListView key={`user-list-view-${index}`}
                        profilePicture={userInfo.profilePicture}
                        userId={userInfo.userId}
                        userName={userInfo.userName}
                        clickHandler={userSelected}
                    />;
                })
            }
        </div>
    );
}

export default UserListing;
