import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () => {
    return (
        <div>
            <ReactLoading type={"spinningBubbles"} color={"#444"}/>
        </div>
        );
}

export default Loading;
