import React from 'react';
import {Badge, Col, Row} from "react-bootstrap";
import './TherapistPills.css';

const TherapistPills = (props: any) => {
    return (
        <div style={{width:'100%'}}>
            <Row>
                <Col style={{textAlign:'center'}}>
                    <h4>{props.title}</h4>
                </Col>
            </Row>
            <Row>
                <Col sm={4} className={'pill-col'}>
                    {props.items[0] &&
                    props.items[0].map((item:any)=>{
                        return <div>
                            {item.description}
                        </div>
                    })
                    }
                </Col>
                <Col sm={4} className={'pill-col'}>
                    {props.items[1] &&
                    props.items[1].map((item:any)=>{
                        return <div>
                            {item.description}
                            <br/>
                        </div>
                    })
                    }
                </Col>
                <Col sm={4} className={'pill-col'}>
                    {props.items[2] &&
                    props.items[2].map((item:any)=>{
                        return <div>
                            {item.description}
                        </div>
                    })
                    }
                </Col>
                <Col sm={4} className={'pill-col'}>
                    {props.items[3] &&
                    props.items[3].map((item:any)=>
                                       {
                        return <div>{item.description}</div>})
                    }
                </Col>
            </Row>
        </div>
    );
}

export default TherapistPills;
