import React, {useContext, useEffect, useReducer, useState} from 'react';
import './user.manager.css';
import UserListing, {UserSource} from "./UserListing/user.listing";
import UserEdit from "./UserEdit/user.edit";
import {UserManagerReducer, UserManagerState} from "./user.manager.reducer";
import {AppContext} from "../../../App";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";
import {displayName} from "../../Utils/utilities";
import UserEditor from "./UserEditor/user.editor";
import SharedToast from "../../Shared/SharedToast/shared.toast";
import MyProfile from "../MyProfile/my.profile";

const initialState : UserManagerState={
    users:[] as UserSource[],
    selectedUser:null,
    addingUser:false,
    editingUser:false,
    editUserId:0
};

export const UserManagerContext = React.createContext<{
    state:UserManagerState;
    dispatch:React.Dispatch<any>;
}>({
    state:initialState,
    dispatch:()=>null});

const UserManager = (props: any) => {
    const [state, dispatch] = useReducer(UserManagerReducer, initialState);
    const [userType, setUserType] = useState('therapist');
    const {users,selectedUser} = state;
    const {state:appState} = useContext(AppContext);
    const {backendurl} = appState;
    const {getAccessTokenSilently} = useAuth0();
    const [localState, setLocalState] = useState(
        {
                    role:'enduser',
                    isAdmin:false,
                    isProvider:false,
                    successToast:false,
                    toastMessage:''
                });

    const loadUserInfo = async ()=>{
        try{
            if(appState.userId === 0)
                return;
            const token = await getAccessTokenSilently();
            const response = await axios.get(`${backendurl}/user/ById/${appState.userId}`,
                                             {
                                                 headers:{
                                                     Authorization:`Bearer ${token}`
                                                 }
                                             });
            if(!response || !response.data)
                return;

            const userInfo = response.data;
            setLocalState({...localState, role: userInfo.role, isAdmin: userInfo.role==='admin', isProvider: userInfo.role==='provider'});
        }catch (error){

        }
    }

    const loadUsers = async ()=>{
        try{
            const token = await getAccessTokenSilently();
            let loadUrl = '';
            if(userType==='therapist'){
                loadUrl = `${backendurl}/user/therapist`;
            }else if(userType==='clients'){
                loadUrl = `${backendurl}/user/clients`;
            }

            console.log(loadUrl);

            const response = await axios.get(loadUrl,
                                             {
                                                 headers:{
                                                     Authorization:`Bearer ${token}`
                                                 }
                                             });
            if(!response || !response.data)
                return
            const users = response.data.filter((user:any)=>{return user.role !== 'admin'});
            const usersSource = users.map((user:any)=>{
                return {profilePicture: user.urlProfilePicture, userId: user.id, userName: displayName(user.firstName, user.firstLastName)} as UserSource
            })
            dispatch({type:'load-users', payload:{...state, users:usersSource}});
        }catch (error){

        }
    }

    useEffect(()=>{
        loadUserInfo().then(r=>{});
    },[]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        console.log(userType);
        loadUsers().then(r=>{});
    },[userType])// eslint-disable-line react-hooks/exhaustive-deps

    const userSelected = async (userId:number) => {
        const userInfo = users.filter((user)=>{return user.userId === userId})[0];
        if(!userInfo)
            return;

        dispatch({type:'select-user', payload:{...state,selectedUser:userInfo}});
    }

    const therapistStatusChanged = (userId:number, status:boolean)=>{
        loadUsers().then(r=>{});
    }

    const userTypeChanged = (userType:string)=>{
        setUserType(userType);
    }

    const OnAddUserClicked = ()=>{
        dispatch({type:'add-user', payload:{...state,addingUser: true}});
    }

    const onAddUserSaved = ()=>{
        dispatch({type:'add-user-done'});
        setLocalState({...localState,successToast: true, toastMessage: 'Usuario agregado'});
        loadUsers().then(r=>{});
    }

    const handleSuccessToastClose = ()=>{
        setLocalState({...localState, successToast: false, toastMessage: ''});
    }

    const onEditUser= (userId:number)=>{
        dispatch({type:'edit-user', payload:{...state,editingUser: true, editUserId: userId}});

    }

    const onProfileSaved = ()=>{
        loadUsers().then(r=>{});
    }
    return (
        <UserManagerContext.Provider value={{state, dispatch}}>
            <div className={'user-manager-container'}>
                <UserListing userSource={users} clickHandler={userSelected} onUserTypeChanged={userTypeChanged}
                    onAddUserClicked={OnAddUserClicked}/>
                {(!state.addingUser && !state.editingUser && <UserEdit
                    profilePicture={selectedUser?selectedUser.profilePicture:''}
                    userId={selectedUser?selectedUser.userId:0}
                    userName={selectedUser?selectedUser.userName:''}
                    onTherapistStatusChange={therapistStatusChanged}
                    userIsAdmin={localState.isAdmin}
                    onUserEdit={onEditUser}
                />)}
                {(state.addingUser &&
                <UserEditor mode={'adding'} onSaveDone={onAddUserSaved}/>)}
                {
                    (state.editingUser &&
                        <MyProfile otherUserId={state.editUserId} profileSaved={onProfileSaved}/>
                    )
                }
            </div>
            <SharedToast
                handleClose={handleSuccessToastClose}
                show={localState.successToast}
                message={localState.toastMessage}
                variant={'success'}
            />
        </UserManagerContext.Provider>
    );
}

export default UserManager;
