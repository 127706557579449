import React, {MutableRefObject, useContext, useRef, useState} from 'react';
import './picture.updater.css';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {Button, Modal, Image} from "react-bootstrap";
import axios from 'axios';
import {useAuth0} from "@auth0/auth0-react";
import {ProfileContext} from "../my.profile";

const PictureUpdater = (props: any) => {
    const [cropper, setCropper] = useState<any>();
    const [invalidName, setInvalidName] = useState(false);
    const {getAccessTokenSilently} = useAuth0();
    const {state:profileState, dispatch:profileDispatcher} = useContext(ProfileContext);
    const {userId,backendUrl,currentPicture, OnPictureChange:pictureChangeCallback} = props;
    const defaultImageSrc = currentPicture? currentPicture : `https://storage.googleapis.com/media-vhg/profile_placeholder.png`;
    const [imageSrc, setImageSrc] = useState(defaultImageSrc);
    const [imageLoaded,setImageLoaded] = useState(false);
    const hiddenFileInput = useRef() as MutableRefObject<HTMLInputElement>;
    const fileFilter = /([^\s]+(?=\.(jpg|gif|png))\.\2)/;

    const initializedHandler = (instance:any)=>{
        setCropper(instance);
    }

    const buttonLoadImageClickHandler = (event:any)=>{
        hiddenFileInput.current.click();
        setInvalidName(false);
    }

    const buttonSaveImageClickHandler = async (event:any)=>{
        if(typeof cropper !== 'undefined'){

            cropper.getCroppedCanvas({
                width:500,
                height:500,
                maxWidth:1024,
                maxHeight:1024
                                     }).toBlob(async (imageBlob:Blob)=>{
                const formData = new FormData();
                formData.append('file',imageBlob);
                const token = await getAccessTokenSilently();
                const response = await axios.post(`${backendUrl}/user/profile/${userId}`,formData,
                                                  {
                                                      headers:{
                                                          Authorization:`Bearer ${token}`,
                                                          'content-type':'multipart/form-data'
                                                      }
                                                  });

                pictureChangeCallback(response.data.urlProfilePicture);
                profileDispatcher({type:'hide-picture-changer', payload:profileState});
            });

        }
    }

    const loadImage = (event:any) =>{
        event.preventDefault();
        let files;
        if(event.dataTransfer){
            files = event.dataTransfer.files;
        }else if(event.target){
            files = event.target.files;
        }
        const reader = new FileReader();
        reader.onload = () =>{
            setImageSrc(reader.result as any);
        };
        if(files && files.length > 0){
            console.log(files[0]);
            if(!fileFilter.test(files[0].name))
            {
                setInvalidName(true);
                return;
            }
            reader.readAsDataURL(files[0]);
            setImageLoaded(true);
        }

    }

    const close = ()=>{
        profileDispatcher({type:'hide-picture-changer', payload:profileState});
    }
    return (
        <div className={'cropper-container'}>
            <Modal onExit={close} onHide={close} show={profileState.showPictureChanger}>
                <Modal.Header closeButton>
                    <Modal.Title>Cambiar foto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {imageLoaded &&
                    <Cropper
                        className={'cropper-tool'}
                        initialAspectRatio={1}
                        aspectRatio={1}
                        src={imageSrc}
                        viewMode={1}
                        guides={true}
                        minCropBoxHeight={100}
                        minCropBoxWidth={100}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={initializedHandler}
                        checkCrossOrigin={false}
                    />
                    }
                    {!imageLoaded &&
                        <Image src={defaultImageSrc} rounded/>
                    }
                    <Button onClick={buttonLoadImageClickHandler}>Seleccione una imagen</Button>
                    {invalidName &&
                        <span className={'invalid-file'}>Archivo invalido</span>
                    }
                    <input type={'file'}
                           className={'picture-file-picker'}
                           accept={'.jpg,.png,.gif'}
                           ref={hiddenFileInput}
                           onChange={loadImage}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'secondary'} onClick={close}>Cerrar</Button>
                    {imageLoaded &&
                        <Button variant={'success'} onClick={buttonSaveImageClickHandler} >Guardar</Button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default PictureUpdater;
