import React, {useContext, useEffect, useState} from 'react';
import './user.edit.css';
import {Button, Image, Modal} from 'react-bootstrap';
import {AppContext} from "../../../../App";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";

export type UserEditProp = {
    profilePicture: string;
    userId: number;
    userName: string;
    onTherapistStatusChange: (userId: number, status:boolean) => void;
    userIsAdmin: boolean;
    onUserEdit:(userId:number)=>void;
}


const UserEdit = (props: UserEditProp) => {
    const {profilePicture, userId, userName,onTherapistStatusChange, userIsAdmin, onUserEdit} = props
    const {state} = useContext(AppContext);
    const {backendurl} = state;
    const {getAccessTokenSilently} = useAuth0();
    const [localState, setLocalState] = useState({isTherapist:false, therapistId:0, confirmDelete:false});


    const loadTherapist = async ()=>{
        try{
            if(userId === 0)
                return;
            const token = await getAccessTokenSilently();
            const response = await axios.get(`${backendurl}/therapy/therapist/byUser/${userId}`,
                                        {
                                            headers:{
                                                Authorization:`Bearer ${token}`
                                            }
                                        });
            if(!response)
            {
                setLocalState({...localState, isTherapist: false});
                return;
            }
            const therapist = response.data;
            if(therapist === null || therapist === '')
            {
                setLocalState({...localState, therapistId:therapist.id, isTherapist: false});
                return;
            }else{
                setLocalState({...localState, therapistId:therapist.id, isTherapist: therapist.isActive});
            }

            //setLocalState({...localState,therapistId:therapist.id, isTherapist: true});
        }catch (error){
            console.log(`Error: ${error}`);
            setLocalState({...localState, isTherapist: false});
        }
    }

    useEffect(()=>{
        loadTherapist().then(r=>{});
    },[userId]);// eslint-disable-line react-hooks/exhaustive-deps

    const activarTerapeuta = async ()=>{
        try{
            const payload = {
                id:localState.therapistId,
                introduction:'',
                biography:'',
                youTubeUrl:null,
                user:{
                    id:userId
                },
                categories:[],
                specialities:[],
                languages:[{id:1}],
                sessions:[],
                links:[]
            };
            const token = await getAccessTokenSilently();
            const response = await axios.post(`${backendurl}/therapy/therapist`,payload,
                                             {
                                                 headers:{
                                                     Authorization:`Bearer ${token}`
                                                 }
                                             });
            setLocalState({...localState, isTherapist: true, therapistId: response.data.id});
            onTherapistStatusChange(userId,true);
        }catch (error){

        }
    }

    const deleteTherapist = async ()=>{
        const token = await getAccessTokenSilently();

        /*await axios.delete(`${backendurl}/therapy/therapist/${localState.therapistId}`,
                           {
                               headers: {
                                   Authorization: `Bearer ${token}`
                               }
                           }
        );*/
        await axios.put(`${backendurl}/therapy/therapist/setisactive/${localState.therapistId}/0`,{},
                           {
                               headers: {
                                   Authorization: `Bearer ${token}`
                               }
                           }
        );
        setLocalState({...localState, isTherapist: false, confirmDelete: false});
        onTherapistStatusChange(userId,false);
    }

    const inactivarTerapeuta = async ()=>{
        setLocalState({...localState,confirmDelete: true});
    }

    const handleClose = ()=>{
        setLocalState({...localState,confirmDelete: false});
    }

    const editarUsuario = ()=>{
        onUserEdit(userId);
    }

    return (
        <div className={'user-edit-container'}>
            {userId!==0 &&
            (
                <div>
                {profilePicture && profilePicture.length>0 && <Image src={profilePicture} className={'user-edit-profile'} rounded/>}
                <h2>{userName}</h2>
                {!localState.isTherapist &&
                    <Button variant={'success'} onClick={activarTerapeuta}>Activar Terapeuta</Button>
                }
                {localState.isTherapist &&
                    <Button variant={'danger'} onClick={inactivarTerapeuta}>Inactivar Terapeuta</Button>
                }
                {userIsAdmin &&
                    <Button variant={'info'} onClick={editarUsuario}>Editar Usuario</Button>
                }
                </div>
            )
            }
            <Modal
                show={localState.confirmDelete}
                onHide={handleClose}
                backdrop={'static'}
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*Inactivar el terapeuta eliminara su información y sera necesario volver a ingresarla, al*/}
                    {/*reactivar el usuario como terapeuta. Esta seguro/a que desea continuar?*/}
                    Esta seguro/a que desea inactivar al terapeuta?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="success" onClick={deleteTherapist}>
                        Si
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UserEdit;
