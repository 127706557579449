import React from 'react';
import './gridMenu.css';
import {FcCalendar, FcSearch, FcList} from 'react-icons/fc';
import {RiFileUserFill} from 'react-icons/ri';
import {FaUserEdit} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const GridMenuCell = (props: any) => {

    const icon = ()=>{
        switch (props.icon){
            case 'calendar':
                return <FcCalendar className={'grid-menu-cell-icon'}/>;
            case 'search':
                return <FcSearch className={'grid-menu-cell-icon'}/>;
            case 'users':
                return <RiFileUserFill className={'grid-menu-cell-icon'}/>;
            case 'profile':
                return <FaUserEdit className={'grid-menu-cell-icon'}/>;
            case 'catalogs':
                return <FcList className={'grid-menu-cell-icon'}/>;
            default:
                return <div></div>
        }
    }

    return (
        <Link to={props.url} className={'grid-menu-cell-a'}>
            <div className={'grid-menu-cell'}>
                {icon()}
                <h3>{props.title}</h3>
            </div>
        </Link>
    )
}

export default GridMenuCell;
