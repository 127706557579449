import React from 'react';
import {Card} from "react-bootstrap";
import './ProviderGalleryCard.css';
import { Link } from 'react-router-dom';

const ProviderGalleryCard = (props: any) => {
    const baseUrl = props.baseUrl?props.baseUrl:'';
    return (
            <Card className={'provider-card'} style={{
                width:'12rem',
                marginLeft:'0.5rem',
                marginBottom:'0.5rem',
                boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius:'5px',
                border:'1px solid black'
            }}>
                <Card.Img variant={'top'} src={props.imgUrl} style={{height:'12rem'}}/>
                <Card.Body style={{height:'5rem'}}>
                    <Card.Title style={{textAlign:'center'}}>{props.title}</Card.Title>
                    <Link to={`${baseUrl}/therapist/${props.therapistId}`}>
                        <div className={'card-overlay'}>
                            Ver Mas
                        </div>
                    </Link>

                </Card.Body>
            </Card>
    );
}

export default ProviderGalleryCard;
