import React from 'react';
import './Landing.css';
import ProviderGallery from "../User/ProviderGallery";
import LandingNavBar from "./LandingNavBar";
import LandingNuestroServicvios from "./LandingNuestroServicvios";
import LandingMasServicio from "./LandingMasServicio";
import LandingAccesoServicios from "./LandingAccesoServicios";
import LandingEmpresas from "./LandingEmpresas";
import LandingQuienesSomos from "./LandingQuienesSomos";
import LandingDerechos from "./LandingDerechos";
import LandingHero from "./LandingHero";
import LandingContactenos from "./LandingContactenos";
import { Switch, Route } from 'react-router-dom';
import TherapistPublicPage from "../Public/TherapistPublicPage";
import {IoLogoWhatsapp as WhatsappIco} from 'react-icons/io';
import LandingFooter from "./LandingFooter";


const Landing = (props: any) => {
    return (
        <div className={'landing'}>
            <LandingNavBar/>
            <Switch>
                <Route path={"/therapist/:id"}>
                    <TherapistPublicPage/>
                </Route>
                <Route path={'/'}>
                    <div id={'page-top'}>
                        <LandingHero/>
                        <LandingNuestroServicvios id={'servicios'}/>
                        <LandingMasServicio/>
                        <ProviderGallery id={'terapeutas'} showCategory={false}/>
                        <LandingAccesoServicios />
                        <LandingEmpresas />
                        <LandingQuienesSomos />
                        {/*<LandingContactenos/>*/}
                        <LandingFooter/>
                        <div className={'what-float flip-scale-up-hor'}>
                            <a href={'https://wa.link/8xc3g1'}>
                                <WhatsappIco className={'whatsapp-icon'}/>
                            </a>
                        </div>
                    </div>
                </Route>
            </Switch>
            {/*<LandingDerechos/>*/}

        </div>


    );
}

export default Landing;
