import {ProfileState} from "./my.profile";

export function MyProfileReducer(state:ProfileState, action:any){
    switch (action.type){
        case 'show-picture-changer':
            return {...action.payload, showPictureChanger:true};
        case 'hide-picture-changer':
            return {...action.payload, showPictureChanger:false};
        default:
            return state;
    }
}
