import React from 'react';
import {Card, CardGroup, Col, Container, Row} from "react-bootstrap";
import imgBeneficios from "../../Assets/Beneficios.png";
import imgMision from "../../Assets/Mission.png";
import imgPrincipios from "../../Assets/Principios.png";

const LandingQuienesSomos = (props: any) => {
    return (
        <Container id={'quienes-somos'} fluid style={{
            background:'#F8F9FA',
            paddingBottom:'2rem'
        }}>
            <Row >
                <Col style={{
                    padding:'2rem'
                }}>
                    <h3 style={{color:'black'}}>QUIÉNES SOMOS</h3>
                </Col>
            </Row>
            <Row>
                <Col></Col>
                <Col className={'col-8'}>
                    <p>
                        Virtual Help es una iniciativa innovadora para el cuidado de la salud mental de manera remota,
                        permitiendo al usuario acceder desde la comodidad de su hogar, oficina o desde cualquier lugar
                        del mundo, a través de dispositivos móviles, computador de escritorio o portátil. Con sistemas
                        operativos compatibles para Android, iOS y Windows. Para su atención contamos con terapeutas
                        acreditados para atenderle en forma segura y confidencial a través de nuestra plataforma.
                    </p>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col className={'col-8'}>
                    {/*style={{width:'80rem'}}*/}
                    <CardGroup >
                        <Card className={'text-center'} >
                            <Card.Img variant={'top'} src={imgBeneficios} style={{height:'15rem'}}/>
                            <Card.Body>
                                <Card.Title>BENEFICIOS</Card.Title>
                                <Card.Text>
                                    La terapia en línea es ideal para aquellos quienes tienen dificultades para asistir
                                    a citas de consejería presencial.La terapia en línea elimina la barrera de la
                                    distancia, permite un manejo del tiempo más eficiente y productivo para usted de
                                    esta manera se eliminan los tiempos de movilización y espera.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className={'text-center'}>
                            <Card.Img variant={'top'} src={imgMision} style={{height:'15rem'}}/>
                            <Card.Body>
                                <Card.Title>MISIÓN</Card.Title>
                                <Card.Text>
                                    Brindar ayuda a través de una guía profesional de servicios de consejería mediante
                                    la conexión virtual con Psicólogos certificados, como una alternativa o complemento
                                    a los servicios de salud mental presenciales.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className={'text-center'} >
                            <Card.Img variant={'top'} src={imgPrincipios} style={{height:'15rem'}}/>
                            <Card.Body>
                                <Card.Title>PRINCIPIOS</Card.Title>
                                <Card.Text>
                                    Responsabilidad<br/>
                                    Sensibilidad Social<br/>
                                    Empatía<br/>
                                    Innovación<br/>
                                    Calidad<br/>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Col>
                <Col></Col>
            </Row>
        </Container>
    );
}

export default LandingQuienesSomos;
