import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import './user.editor.css';
import {useAuth0} from "@auth0/auth0-react";
import {AppContext} from "../../../../App";
import axios from "axios";
import {TherapistType} from "../../../Utils/types";
import {LoadTherapistTypes} from "../../../Utils/utilities";

export type UserEditorProp = {
    mode:string;
    onSaveDone:()=>void;
}

export type UserEditorState = {
    id:string;
    auth0Id:string;
    userName:string;
    firstName:string;
    middleName:string;
    firstLastName:string;
    secondLastName:string;
    email:string;
    urlProfilePicture:string;
    useGravatar:boolean;
    role:string;
    password:string;
    passConfirm:string;
    errors:boolean[];
    errorMessages:string[];
    therapistType:number;
}

const initialState:UserEditorState = {
    id:'',
    auth0Id:'',
    userName:'',
    firstName:'',
    middleName:'',
    firstLastName:'',
    secondLastName:'',
    email:'',
    urlProfilePicture:'',
    useGravatar:false,
    role:'enduser',
    password:'',
    passConfirm:'',
    errors:[false,false,false,false,false,false,false],
    errorMessages:[],
    therapistType: 1
}

function UserEditor(props: UserEditorProp) {
    const {onSaveDone} = props;
    const [editorState, setEditorState] = useState(initialState);
    const {getAccessTokenSilently} = useAuth0();
    const {state} = useContext(AppContext);
    const {backendurl} = state;
    const [therapistTypes, setTherapistTypes] = useState([] as TherapistType[]);

    const handleInputChange = (event:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLSelectElement>)=>{
        const name = event.target.name;
        const value = event.target.value;
        const newState = {...editorState,[name]:value};
        setEditorState(newState);
    }

    const loadTherapistTypes = async ()=>{
        const token = await getAccessTokenSilently();
        const types = await LoadTherapistTypes(backendurl, token);
        setTherapistTypes(types);
    }

    const validate = ():boolean=>{
        const errors = [false,false,false,false,false,false,false];
        const errorMsgs = [] as string[];

        if(editorState.userName.trim().length===0){
            errors[0]=true;
            errorMsgs.push('Usuario requerido');
        }
        if(editorState.firstName.trim().length===0){
            errors[1]=true;
            errorMsgs.push('Primer Nombre requerido');
        }
        if(editorState.firstLastName.trim().length===0){
            errors[3]=true;
            errorMsgs.push('Primer Apellido requerido');
        }
        if(editorState.email.trim().length===0){
            errors[5]=true;
            errorMsgs.push('Correo electrónico requerido');
        }
        // eslint-disable-next-line
        if(!editorState.email.match(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi)){
            errors[5]=true;
            errorMsgs.push('Formato de correo electrónico invalido');
        }
        if(editorState.password.trim().length===0){
            errors[6]=true;
            errorMsgs.push('Contraseña requerida');
        }
        if(editorState.passConfirm.trim().length===0){
            errors[6]=true;
            errorMsgs.push('Verificación contraseña requerida');
        }
        if(editorState.password !== editorState.passConfirm){
            errors[6]=true;
            errorMsgs.push('Contraseña y verificación no son iguales');
        }
        if(editorState.password.match(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})/g) === null){
            debugger;
            errors[6]=true;
            errorMsgs.push('Contraseña debil');
            errorMsgs.push('debe tener de 8 a 64 letras');
            errorMsgs.push('debe tener mayusculas y minusculas');
            errorMsgs.push('debe tener un número');
            errorMsgs.push('debe tener un caracter especial (!,#,$)');
        }

        const newState = {...editorState, errors:errors, errorMessages: errorMsgs};
        setEditorState(newState);
        return errorMsgs.length === 0;
    }

    const handleSave = async ()=>{
        if(!validate())
            return;

        const token = await getAccessTokenSilently();
        try {
            const response = await axios.post(`${backendurl}/therapy/createaz`,
                                              {
                                                  id: '',
                                                  auth0Id: '',
                                                  userName: editorState.userName,
                                                  firstName: editorState.firstName,
                                                  middleName: editorState.middleName,
                                                  firstLastName: editorState.firstLastName,
                                                  secondLastName: editorState.secondLastName,
                                                  email: editorState.email,
                                                  urlProfilePicture: '',
                                                  useGravatar: false,
                                                  role: editorState.role,
                                                  password: editorState.password,
                                                  therapistType: editorState.therapistType
                                              }
                ,
                                              {
                                                  headers: {
                                                      Authorization: `Bearer ${token}`
                                                  }
                                              });
            if(response.status===201){

                onSaveDone();
            }else{
                setEditorState({...editorState,errorMessages:[`Error al guardar el usuario:${response.statusText}`]})
            }
        }catch (e){
            setEditorState({...editorState,errorMessages:[`Error al guardar el usuario:${e}`]})
        }
    }

    useEffect(()=>{
        loadTherapistTypes().then(r=>{});
    },[])

    return (
        <div className={'user-editor-container'}>
            <div className={'user-editor-form'}>
                <label htmlFor={'userName'} className={'user-editor-form-label'}>Usuario: </label>
                <input type={'text'} id={'userName'} name={'userName'}
                       onChange={handleInputChange}
                       className={`user-editor-form-input ${editorState.errors[0]?'user-editor-form-input-error':''}`}
                       value={editorState.userName}
                />
                <label htmlFor={'firstName'} className={'user-editor-form-label'}>Primer Nombre: </label>
                <input type={'text'} id={'firstName'} name={'firstName'}
                       onChange={handleInputChange}
                       className={`user-editor-form-input ${editorState.errors[1]?'user-editor-form-input-error':''}`}
                       value={editorState.firstName}
                />
                <label htmlFor={'middleName'} className={'user-editor-form-label'}>Segundo Nombre: </label>
                <input type={'text'} id={'middleName'} name={'middleName'}
                       onChange={handleInputChange}
                       className={`user-editor-form-input ${editorState.errors[2]?'user-editor-form-input-error':''}`}
                       value={editorState.middleName}
                />
                <label htmlFor={'firstLastName'} className={'user-editor-form-label'}>Primer Apellido: </label>
                <input type={'text'} id={'firstLastName'} name={'firstLastName'}
                       onChange={handleInputChange}
                       className={`user-editor-form-input ${editorState.errors[3]?'user-editor-form-input-error':''}`}
                       value={editorState.firstLastName}
                />
                <label htmlFor={'secondLastName'} className={'user-editor-form-label'}>Segundo Apellido: </label>
                <input type={'text'} id={'secondLastName'} name={'secondLastName'}
                       onChange={handleInputChange}
                       className={`user-editor-form-input ${editorState.errors[4]?'user-editor-form-input-error':''}`}
                       value={editorState.secondLastName}
                />
                <label htmlFor={'email'} className={'user-editor-form-label'}>Correo Electr&oacute;nico: </label>
                <input type={'email'} id={'email'} name={'email'}
                       onChange={handleInputChange}
                       className={`user-editor-form-input ${editorState.errors[5]?'user-editor-form-input-error':''}`}
                       value={editorState.email}
                />
                <label htmlFor={'password'} className={'user-editor-form-label'}>Contraseña: </label>
                <input type={'password'} id={'password'} name={'password'}
                       onChange={handleInputChange}
                       className={`user-editor-form-input ${editorState.errors[6]?'user-editor-form-input-error':''}`}
                />
                <label htmlFor={'passConfirm'} className={'user-editor-form-label'}>Verificar Contraseña: </label>
                <input type={'password'} id={'passConfirm'} name={'passConfirm'}
                       onChange={handleInputChange}
                       className={`user-editor-form-input ${editorState.errors[6]?'user-editor-form-input-error':''}`}
                />
                <label htmlFor={'role'} className={'user-editor-form-label'}>Tipo Usuario: </label>
                <select id={'role'} name={'role'}
                       onChange={handleInputChange}
                       className={'user-editor-form-input'}
                        value={editorState.role}
                >
                    <option key={'enduser'} value={'enduser'}>Cliente</option>
                    <option key={'provider'} value={'provider'}>Proveedor</option>
                    <option key={'admin'} value={'admin'}>Administrador</option>
                </select>
                {editorState.role==='provider' &&
                    <div className={'therapist-type-container'}>
                        <label htmlFor={'therapistType'} className={'user-editor-form-label'}>Tipo Proveedor: </label>
                        <select id={'therapistType'} name={'therapistType'}
                                onChange={handleInputChange}
                                className={'user-editor-form-input'}
                                value={editorState.therapistType}
                                >
                            {therapistTypes.map(opt=>{
                                return <option key={`tt${opt.id}`} value={opt.id}>{opt.name}</option>
                            })}
                        </select>
                    </div>
                }

                <button className={'user-editor-form-guardar'} onClick={handleSave}>Guardar</button>
                {(editorState.errorMessages.length>0) &&
                    <div className={'user-editor-form-error-container'}>
                        {editorState.errorMessages.map((msg,idx)=>{
                            return <div key={`errMsg${idx}`}>{msg}<br/></div>
                        })}
                    </div>
                }
            </div>
        </div>
    );
}

export default UserEditor;
