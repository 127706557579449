import React, {useContext, useEffect, useState} from 'react';
import './session.modal.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import {Button, Col, Container, Modal, Row} from 'react-bootstrap';
import {PlannerContext} from "../planner";
import ProviderPicker from "../Controll/ProviderPicker/provider.picker";
import ClientPicker from "../Controll/ClientPicker/client.picker";
import DatePicker, {registerLocale} from 'react-datepicker';
import {DateTime} from "luxon";
import es from 'date-fns/locale/es';
import {newSessionCode} from "../../../Utils/utilities";
import DurationPicker from "../Controll/DurationPicker/duration.picker";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";
import {AppContext} from "../../../../App";
import {ClientEvent, DurationEvent, Session, SessionData, TherapistEvent} from "./session.modal.types";
registerLocale('es', es)

const SessionModal = (props: any) => {
    const {state, dispatch} = useContext(PlannerContext);
    const {state:appState} = useContext(AppContext);
    const {backendurl} = appState;
    const {getAccessTokenSilently} = useAuth0();
    const editMode = props.editMode === 'edit';
    const selectedSession = props.session?props.session as Session:null;

    const [sessionData,setSessionData] = useState({
        therapistId:0,
        clientId:0,
        code:newSessionCode(0,0,DateTime.local()),
        dateTime: DateTime.local(),
        duration:0} as SessionData);

    const [errors, setErrors] = useState({
        therapistEmpty:false,
        clientEmpty:false,
        dateInThePast:false,
        durationEmpty:false
                                        });

    const now = DateTime.local();

    useEffect(()=>{
        const instance = async()=>{
            if(selectedSession)
                await setSessionData({
                    therapistId:selectedSession.therapist.id,
                    clientId:selectedSession.participants[0].id,
                    code:selectedSession.code,
                    dateTime:DateTime.fromFormat(`${selectedSession.date} ${selectedSession.time}`,'yyyy-MM-dd hh:mm:ss'),
                    duration:selectedSession.duration
                                     });
        }
        instance();
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const save = async ()=>{
        const {therapistId, clientId, dateTime, duration,code} = sessionData;
        if(therapistId === 0){
            setErrors({...errors, therapistEmpty: true});
            return;
        }
        if(clientId === 0){
            setErrors({...errors, clientEmpty:true});
            return;
        }
        if(dateTime <= now){
            setErrors({...errors, dateInThePast: true});
            return;
        }
        if(duration<=0){
            setErrors({...errors, durationEmpty: true});
            return;
        }

        const payload:Session = {
            id: selectedSession?selectedSession.id:null,
            code:code,
            date:dateTime.toFormat('yyyy-MM-dd'),
            time:dateTime.toFormat('HH:mm:ss'),
            therapist:therapistId,
            participants:[
                {id:clientId}
            ],
            maxParticipants:2,
            duration:duration,
            expired:false
        };

        try{
            const token = await getAccessTokenSilently();
            if(selectedSession)
            {
                await axios.put(`${backendurl}/therapy/session`,payload,
                                 {
                                     headers:{
                                         Authorization:`Bearer ${token}`
                                     }
                                 });
                const updatedSession = await axios.get(`${backendurl}/therapy/session/${selectedSession.id}`,
                                {
                                    headers:{
                                        Authorization:`Bearer ${token}`
                                    }
                                });

                const currentSessions = state.currentSessions.filter(x=> x.id !== updatedSession.data.id);
                currentSessions.push(updatedSession.data);

                dispatch({type:'show-sessions',payload:{...state,currentSessions: currentSessions}});
            }else{
                await axios.post(`${backendurl}/therapy/session`,payload,
                                 {
                                     headers:{
                                         Authorization:`Bearer ${token}`
                                     }
                                 });
            }

            dispatch({type:'save-modal-success', payload:{...state,successMessage:'Sesión creada con exito.'}});
        }catch (error){
            console.log(error);
            dispatch({type:'save-modal-error', payload:{...state,errorMessage:'Error al guardar la sesión.'}});
        }
    }

    const close=()=>{
        dispatch({type:'close-modal', payload:{...state, launchAddModal:false}});
    }

    const providerChanged=(event:TherapistEvent)=>{
        const therapistId = event.value;
        const code = newSessionCode(therapistId, sessionData.clientId, sessionData.dateTime);
        setSessionData({...sessionData, code, therapistId});
        if(therapistId !== 0)
            setErrors({...errors, therapistEmpty: false});
    }

    const clientChanged = (event:ClientEvent) => {
        const clientId = event.value;
        const code = newSessionCode(sessionData.therapistId, clientId, sessionData.dateTime);
        setSessionData({...sessionData, code, clientId});
        if(clientId !== 0)
            setErrors({...errors, clientEmpty: false});
    }

    const dateChanged = (event:Date) => {
        const dateTime = DateTime.fromJSDate(event);
        const code = newSessionCode(sessionData.therapistId, sessionData.clientId, sessionData.dateTime);
        setSessionData({...sessionData, code, dateTime:dateTime});
        if(dateTime > now)
            setErrors({...errors, dateInThePast: false});
    }

    const durationChanged = (event:DurationEvent) =>{
        const duration = event.value;
        setSessionData({...sessionData, duration});
        if(duration > 0)
            setErrors({...errors, durationEmpty: false});
    }

    let selectedDate = sessionData.dateTime.toJSDate();
    let selectedCode = sessionData.code;
    let selectedDuration = sessionData.duration;

    return (
        <div className={'session-modal-container'} >
            <Modal onExit={close} onHide={close} show={state.launchAddModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Sesión</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                <h4>Terapeuta</h4>
                                <ProviderPicker
                                    changeHandler={providerChanged}
                                    noImage={true}
                                    skipAllOption={true}
                                    isDisabled={!editMode}
                                    selectedValue={selectedSession?selectedSession.therapist.id:null}
                                />
                                {errors.therapistEmpty && <p className={'session-modal-error'}>
                                    Debe seleccionar un terapeuta
                                </p>}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4>Cliente</h4>
                                <ClientPicker
                                    changeHandler={clientChanged}
                                    isDisabled={!editMode}
                                    selectedValue={selectedSession?selectedSession.participants[0].id:null}
                                />
                                {errors.clientEmpty && <p className={'session-modal-error'}>
                                    Debe seleccionar un cliente
                                </p>}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4>Fecha y Hora</h4>
                                <DatePicker
                                    locale={'es'}
                                    selected={selectedDate}
                                    onChange={dateChanged}
                                    showTimeSelect
                                    dateFormat={'dd/MM/yyyy hh:mm aa'}
                                    timeCaption={'Hora:'}
                                    timeFormat={'hh:mm aa'}
                                    disabled={!editMode}
                                />
                                {errors.dateInThePast && <p className={'session-modal-error'}>
                                    La fecha y hora no pueden ser menor o igual a la fecha y hora actual
                                </p>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className={'session-modal-code'}>
                                <h4>Código de Sesión</h4>
                                {selectedCode}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <h4>Duración</h4>
                                    <DurationPicker
                                        changeHandler={durationChanged}
                                        selected={selectedDuration}
                                        editMode={!editMode}

                                    />
                                    {errors.durationEmpty && <p className={'session-modal-error'}>
                                        Debe seleccionar una duración
                                    </p>}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'secondary'} onClick={close}>Cerrar</Button>
                    {editMode &&
                        <Button variant={'primary'} onClick={save} >Guardar</Button>
                    }
                </Modal.Footer>

            </Modal>
        </div>
    );
}

export default SessionModal;
