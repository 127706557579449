import React from 'react';
import './Terminos.css';

const Terminos = () => {
    return (
        <div className={'terminos'}>
            <h1>TERMINOS Y CONDICIONES</h1>
            <p>Por favor, lea estos Términos de Servicio cuidadosamente. Al acceder o utilizar cualquier parte del sitio, Estas Condiciones de Servicio se aplican a todos los usuarios Al firmar este Consentimiento Informado declara que es mayor de edad y lo hace en su propio nombre.
            La terapia psicológica es una relación entre personas que trabajan profesionalmente en colaboración y búsqueda del objetivo común de mejorar la calidad de vida y aumentar el bienestar psicológico del consultante. Por lo tanto, conlleva derechos y responsabilidades por parte de cada uno. Antes de decidir iniciar el proceso, es muy importante que entienda con claridad sus derechos y responsabilidades como paciente.
            Los terapeutas están conscientes de la responsabilidad de ofrecerle el tratamiento psicológico que más se adecúe a sus necesidades y objetivos acordados conjuntamente con base en un proceso exhaustivo de evaluación y que tenga evidencia científica y actualizada sobre su nivel de efectividad. En ese sentido el  compromiso es dedicarle el tiempo necesario, el conocimiento profesional actualizado y los esfuerzos que estén dentro de mis posibilidades para lograr los objetivos terapéuticos propuestos.
            Por su parte, como paciente, tiene el derecho de conocer las ventajas y desventajas de los distintos procedimientos de tratamiento con base en evidencia que los terapeutas puede ofrecer, para que pueda tomar una decisión informada, y si lo acepta, se compromete a cumplir con las indicaciones debidamente fundamentadas del terapeuta. En el caso de que la intervención que requiera deba ser realizada por profesionales de otras disciplinas diferentes de la psicología o que esté fuera de mis competencias o campo de trabajo, tengo la responsabilidad de darle información sobre otros profesionales que estén en capacidad de aplicar el tratamiento.
            Por lo general, en las siguientes 2 o 3 sesiones, realizamos una evaluación exhaustiva de su contexto, estilo de vida, estado psicológico e historia personal pertinente, así como de sus necesidades y, con base en eso, acordaremos en forma conjunta los objetivos específicos de trabajo, así como los indicadores concretos de progreso. Al final de la evaluación, discutiremos la formulación clínica sobre la naturaleza y causas de su condición particular, sus necesidades específicas y sus valores y objetivos vitales, que servirán de guía en el proceso de planeación, diseño y evaluación del proceso terapéutico.
            </p>

            <p>
            1 - Términos Virtual Help
            Al utilizar este sitio, declaras que tienes al menos la mayoría de edad en tu país de residencia. No puedes usar nuestros productos y servicios con ningún propósito ilegal o no autorizado, por ejemplo grabar una sesión lo cual está prohibido.
            </p>
            <p>
            2 - Condiciones Generales
            Nos reservamos el derecho de rechazar la prestación de servicios a cualquier persona, por cualquier motivo y en cualquier momento.  Nuestros terapeutas no prescriben medicamentos a través de Virtual Help. El costo por sesión es de $25.00 USD o su equivalente en lempiras + iva. Teniendo  una duración de 50 minutos. Los pagos son anticipados y es usted quien programa la fecha y hora a la que puede puedas asistir a la cita virtual.
            Una vez reservada en una fecha y hora específica y que el psicólogo que has elegido estará disponible en esa sesión a través de videoconferencia en la fecha y hora programada. Si por algún inconveniente el psicólogo no puede asistir, serás notificado a través de email y tu sesión será reprogramada cuando ambos lo acuerden.
            Aceptas que, si por algún inconveniente no puedes asistir a tu sesión programada, deberás cancelarla enviando un email a la siguiente dirección: consultas@virtualhelpgroup.com, con al menos 6 horas de anticipación, de lo contrario será tomada como realizada.
            Reconoces que al cancelar una sesión con al menos 6 horas de anticipación puedes reprogramarla una vez más o dejarla en espera hasta que puedas asistir.
            Aceptas que debes contar con los requerimientos técnicos adecuados para llevar acabo la videoconferencia.
            </p>
            <p>
            3 - Modificaciones al Servicio y Precios
            Los precios de nuestros productos están sujetos a cambio sin aviso (a no  ser que se haya realizado un pago previo por el servicio en esa instancia se mantendrá el precio). Nos reservamos el derecho de modificar o discontinuar el Servicio sin aviso previo.
            También es posible que, en el futuro, te ofrezcamos nuevos servicios y/o características a través del sitio web (incluyendo el lanzamiento de nuevas herramientas y recursos). Estas nuevas características y/o servicios también estarán sujetos a estos Términos de Servicio.
            </p>
            <p>
            5 - Información Personal
            Tu presentación de información personal será resguardada a través del sitio sin embargo. La confidencialidad tiene un límite,; la información recibida en sentido profesional solo puede revelarse tras la más cuidadosa consideración, cuando existe un peligro claro e inminente para sí mismo, para un individuo,  y para la sociedad, así mismo en caso de abuso sexual  infantil o abuso físico en un adulto mayor en estos casos no se aplica la confidencialidad , y el profesional está obligado a denunciar estos casos o dar avisos  únicamente con los profesionales apropiados o las autoridades públicas pertinentes.
            </p>
            <p>
            6 - Exclusión de Garantías y Limitación de Responsabilidad
            No garantizamos ni aseguramos que el uso de nuestro servicio será ininterrumpido, puntual o libre de errores. No garantizamos que los resultados que se puedan obtener del uso del servicio serán exactos o confiables sin embargo trabajamos para acércanos lo más posible. Aceptas que de vez en cuando podemos quitar el servicio por períodos de tiempo indefinidos o cancelar el servicio en cualquier momento sin previo aviso. Aceptas expresamente que el uso de, o la posibilidad de utilizar, el servicio es bajo tu propio riesgo.
            </p>
            <p>
            7 - Cancelación
            Las obligaciones y responsabilidades de las partes que hayan incurrido con anterioridad a la fecha de terminación sobrevivirán a la terminación de este acuerdo a todos los efectos. Estas Condiciones de servicio son efectivos a menos que y hasta que sea terminado por ti o nosotros. Puedes terminar estos Términos de Servicio en cualquier momento por avisarnos que ya no deseas utilizar nuestros servicios, o cuando dejes de usar nuestro sitio. Si a nuestro juicio, fallas, o se sospecha que ha fallado, en el cumplimiento de cualquier término o disposición de estas Condiciones de Servicio, también podemos terminar este acuerdo en cualquier momento sin previo aviso, y seguirás siendo responsable de todos los montos adeudados hasta incluida la fecha de terminación; y/o en consecuencia podemos negarte el acceso a nuestros servicios (o cualquier parte del mismo).
            </p>
            <p>
            8- Ley
            Las Condiciones del servicio y cualquier acuerdo aparte en el que te proporcionemos servicios se regirán e interpretarán en conformidad con las leyes del colegio de psicólogos de Honduras
            </p>
            <p>
            9 - Cambios en los Términos y Condiciones del Servicio
            Puedes revisar la versión más actualizada de los Términos de Servicio en cualquier momento en esta página. Nos reservamos el derecho, a nuestra sola discreción, de actualizar, modificar o reemplazar cualquier parte de estas Condiciones del servicio mediante la publicación de las actualizaciones y los cambios en nuestro sitio web. Es tu responsabilidad revisar nuestro sitio web periódicamente para verificar los cambios. El uso continuo de o el acceso a nuestro sitio Web o el Servicio después de la publicación de cualquier cambio en estas Condiciones de servicio implica la aceptación de dichos cambios.
            </p>
            <p>
            10 - Información de Contacto
            Preguntas y reclamos acerca de los términos y condiciones del servicio deben ser enviadas a contacto@virtualhelpgroup.com
            Al darle si he leído y acepto estás aceptando los Términos de Servicio. Si no estás de acuerdo con todos los términos y condiciones de este acuerdo, entonces no deberías acceder a la página web o usar cualquiera de los servicios y debe darle click a no acepto
            </p>
        </div>
    );
};

export default Terminos;
