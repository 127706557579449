import React from 'react';
import {Toast} from "react-bootstrap";
import {MdError, MdCheckCircle} from 'react-icons/md';
import './shared.toast.css';

const SharedToast = (props: any) => {
    let className = '';
    if(props.variant === 'success')
        className = 'shared-toast success-toast';
    if(props.variant === 'error')
        className = 'shared-toast error-toast';

    return (
        <div>
            <Toast onClose={props.handleClose} show={props.show} delay={3000} autohide className={className}>
                <Toast.Header>
                    {props.variant === 'success' && <MdCheckCircle/>}
                    {props.variant === 'error' && <MdError/>}
                    <strong className="mr-auto">
                        {props.variant === 'success' && 'Exito'}
                        {props.variant === 'error' && 'Error'}
                    </strong>
                </Toast.Header>
                <Toast.Body>{props.message}</Toast.Body>
            </Toast>
        </div>
    );
}

export default SharedToast;
