import {DateTime} from "luxon";
import axios from "axios";
import {Category, Language, Speciality, TherapistType} from "./types";

export const fullName=(firstName:string, middleName:string, firstLastName:string, secondLastName:string)=>{
    return `${firstName}${middleName?` ${middleName}`:''}\
         ${firstLastName}${secondLastName?` ${secondLastName}`:''}`;
}

export const displayName=(firstName:string, firstLastName:string)=>{
    return `${firstName} ${firstLastName}`;
}

export const newSessionCode= (therapistId:number,clientId:number, dateTime:DateTime)=>{
    const now = DateTime.local();
    const transformedIds = `${`00${therapistId}`.substr(-3,3)}${`00${clientId}`.substr(-3,3)}`;
    const seed =  Math.round(Math.random()*100);
    const checkSum = dateTime.year+dateTime.month+dateTime.day+dateTime.hour+dateTime.minute
        +now.year+now.month+now.minute+now.hour+now.minute+seed;

    return `S${transformedIds}${checkSum}`;
}

export const LoadCategories = async (
    backendurl: string,
    therapistType: number = 1
):Promise<Category[]> =>{
    const response = await axios.get(`${backendurl}/public/categories?type=${therapistType}`);
    if(response.data){
        return response.data.map((res:any)=>{
            return {id:res.id, description:res.description} as Category;
        })
    }else{
        return [];
    }
}

export const LoadSpecialities = async (backendurl:string,therapistType: number = 1):Promise<Speciality[]> =>{
    const response = await axios.get(`${backendurl}/public/specialities?type=${therapistType}`);
    if(response.data){
        return response.data.map((res:any)=>{
            return {id:res.id, description:res.description} as Speciality;
        })
    }else{
        return [];
    }
}

export const LoadLanguages = async(backendurl:string):Promise<Language[]> =>{
    const response = await axios.get(`${backendurl}/public/languages`);
    if(response.data){
        return response.data.map((res:any)=>{
            return {id:res.id, name:res.description} as Language;
        })
    }else{
        return [];
    }
}

export const LoadTherapistTypes = async(backendurl:string, token:string):Promise<TherapistType[]> =>{
    const response = await axios.get(`${backendurl}/therapy/types`,
                                     {
                                         headers:{
                                             Authorization:`Bearer ${token}`
                                         }
                                     });
    if(response.data){
        return response.data;
    }else{
        return [];
    }
}

export const LoadTherapistTypesPublic = async(backendurl:string):Promise<TherapistType[]> =>{
    const response = await axios.get(`${backendurl}/public/therapist-types`);
    if(response.data){
        return response.data.map((res:any)=>{
            return {id:res.id, name:res.description} as TherapistType;
        })
    }else{
        return [];
    }
}



export const isNumber = (test:string)=>{
    return !isNaN(Number(test));
}
