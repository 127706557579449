import React, {useContext, useEffect, useState} from 'react';
import './gridMenu.css';
import { Container } from 'react-bootstrap';
import GridMenuCell from "./gridMenuCell";
import {useRouteMatch} from "react-router-dom";
import {AppContext} from "../../../App";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";

const GridMenu = (props: any) => {
    const {path} = useRouteMatch();
    const {state} = useContext(AppContext);
    const [localState, setLocalState] = useState({role:'enduser',isAdmin:false,isProvider:false});
    const {backendurl} = state;
    const {getAccessTokenSilently} = useAuth0();

    const loadUserInfo = async ()=>{
        try{
            if(state.userId === 0)
                return;
            const token = await getAccessTokenSilently();
            const response = await axios.get(`${backendurl}/user/ById/${state.userId}`,
                                       {
                                           headers:{
                                               Authorization:`Bearer ${token}`
                                           }
                                       });
            if(!response || !response.data)
                return;

            const userInfo = response.data;
            setLocalState({...localState, role: userInfo.role, isAdmin: userInfo.role==='admin', isProvider: userInfo.role==='provider'});
        }catch (error){

        }
    }

    useEffect(()=>{
        loadUserInfo().then(r=>{});
    },[state.userId])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {localState.isAdmin &&
                <Container fluid className={'grid-menu-container'}>
                    <GridMenuCell title={'Citas'} icon={'calendar'} url={`${path}/planner`}/>
                    <GridMenuCell title={'Buscar'} icon={'search'} url={'/protected/search'}/>
                    <GridMenuCell title={'Usuarios'} icon={'users'} url={`${path}/user-manager`}/>
                    <GridMenuCell title={'Catalogos'} icon={'catalogs'} url={`${path}/catalogs`}/>
                </Container>
            }
            {localState.isProvider &&
            <Container fluid className={'grid-menu-container'}>
                <GridMenuCell title={'Mi Perfil'} icon={'profile'} url={`${path}/myprofile`}/>
            </Container>
            }
        </div>

    );
}

export default GridMenu;
