import React, {useContext, useEffect, useState, useRef} from 'react';
import {IoIosMic,IoIosMicOff,IoMdEye,IoMdEyeOff,IoIosCloseCircle} from 'react-icons/io';
import AgoraRTC, {
    ConnectionDisconnectedReason,
    ConnectionState,
    IAgoraRTCClient, IAgoraRTCRemoteUser,
    ILocalAudioTrack,
    ILocalVideoTrack
} from "agora-rtc-sdk-ng";
import {isSafari, browserVersion} from 'react-device-detect';
import './Session.css';
import {AppContext} from "../../App";
import {useHistory, useLocation} from 'react-router-dom';
import WebFont from 'webfontloader';
import axios from "axios";
import Terminos from "./Terminos";
import {Button} from "react-bootstrap";

function Session() {
    const {state} = useContext(AppContext);
    const {backendurl} = state;
    const sdkCodec = (isSafari && parseFloat(browserVersion) <= 12.1)?"h264":"vp8";
    const [isMute,setIsMute] = useState(false);
    const [isVideoOn, setIsVideoOn] = useState(true);
    const history = useHistory();

    const client = useRef<IAgoraRTCClient>();
    const localVideoTrack = useRef<ILocalVideoTrack>();
    const localAudioTrack = useRef<ILocalAudioTrack>();
    const [sessionId, setSessionId] = useState('');
    const [terminosAceptados, setTerminosAceptados] = useState(false);

    useEffect(()=>{
        WebFont.load({
            google:{
                families:['Aleo']
            }
                     })
    },[]);

    const useQuery = ()=>{
        return new URLSearchParams(useLocation().search);
    }

    const queryParams = useQuery();

    const connectionStateChanged =
        async (curState:ConnectionState, revState:ConnectionState, reason?:ConnectionDisconnectedReason)=>{
        console.log("ConnectionState:", curState);
        if(curState === "CONNECTED"){
            await client.current?.publish([localAudioTrack.current as ILocalAudioTrack,localVideoTrack.current as ILocalVideoTrack]);
        }

    }

    const userPublished = async(user:IAgoraRTCRemoteUser, mediaType:"video" | "audio")=>{
        await client.current?.subscribe(user,mediaType)
        if(mediaType === "audio"){
            const audioTrack = user.audioTrack;
            audioTrack?.play();
        }else{
            const videoTrack = user.videoTrack;
            videoTrack?.play('RemoteVideo');
        }

    }

    const addEventListeners = ()=>{
        client.current?.on("connection-state-change",connectionStateChanged);
        client.current?.on("user-published",userPublished);
    }

    const initClient = async ()=>{
        client.current = AgoraRTC.createClient({codec:sdkCodec, mode:"rtc"});
        addEventListeners();
        [localAudioTrack.current,localVideoTrack.current] = await AgoraRTC.createMicrophoneAndCameraTracks();
        localVideoTrack.current.play("LocalVideo");
        let sesId = '00000';
        if(queryParams.get("session-id")){
            sesId = queryParams.get("session-id") as string;
        }
        sesId = sesId.toUpperCase();
        setSessionId(sesId);
        await client.current.join('849e8a4e9e2e44af91dff791377a8ace',sesId,null,0);
    }

    useEffect(()=>{
        if(terminosAceptados){
            initClient()
            return function cleanUp(){
                localVideoTrack.current?.close();
                localAudioTrack.current?.close();
            }
        }
    },[terminosAceptados])

    const toggleMute = async ()=>{
        await localAudioTrack.current?.setEnabled(isMute)
        setIsMute(!isMute);
    }

    const toggleVideo = async ()=>{
        await localVideoTrack.current?.setEnabled(!isVideoOn);
        if(isVideoOn){
            await localVideoTrack.current?.stop()
        }else{
            await localVideoTrack.current?.play('LocalVideo')
        }
        setIsVideoOn(!isVideoOn);
    }

    const exit = async()=>{
        await client.current?.leave();
        history.push("/");
    }

    const aceptarClick = async()=>{
        setTerminosAceptados(true);
    }
    return (
        <div>
        {terminosAceptados &&
        <div>
            <div id={"RemoteVideo"} className={"remote-video-container"}></div>
            <div className={'control-container'}>
                <div onClick={toggleMute}>
                    {isMute && <IoIosMicOff className={'muteOn'}/>}
                    {!isMute && <IoIosMic className={'muteOff'}/>}
                </div>
                <div onClick={exit}>
                    <IoIosCloseCircle className={'exit-button'}/>
                </div>
                <div onClick={toggleVideo}>
                    {isVideoOn && <IoMdEye className={'videoOn'}/>}
                    {!isVideoOn && <IoMdEyeOff className={'videoOff'}/>}
                </div>
                <div className={'logo'}>
                    Virtual Help
                </div>
            </div>
            <div id={"LocalVideo"} className={"local-video-container"}></div>
        </div>}
        {!terminosAceptados &&
        <div className={'terminos-container'}>
            <Terminos/>
            <Button onClick={aceptarClick}>Aceptar</Button>
        </div>}
        </div>
    );
}

export default Session;
