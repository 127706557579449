import React from 'react';
import imgAcceso from '../../Assets/Acceso.png';
import {FaWifi as IcoWifi} from 'react-icons/fa';
import {FaCalendarAlt as IcoCal} from 'react-icons/fa';
import {FaPersonBooth as IcoRoom} from 'react-icons/fa';
import {MdComputer as IcoComp} from 'react-icons/md';
import {BiHeadphone as IcoHead} from 'react-icons/bi';

const LandingAccesoServicios = (props: any) => {
    const iconSize='32px';

    return (
            <div id={'requisitos'} className={'lnd-req-container'}>
                <div className={'lnd-req-req-list'}>
                    <div>
                        <h3>¿Qué necesita para usar nuestros servicios?</h3>
                    </div>
                    <div>
                        <IcoWifi style={{width:iconSize,height:iconSize,color:'#17A2B8'}}/> Una conexión estable a internet
                    </div>
                    <div>
                        <IcoCal style={{width:iconSize,height:iconSize,color:'#FD2071'}}/> Programar la hora de tu sesión
                    </div>
                    <div>
                        <IcoRoom style={{width:iconSize,height:iconSize,color:'#DC3545'}}/> Una habitación tranquila o espacio donde puedas hablar en confianza.
                    </div>
                    <div>
                        <IcoComp style={{width:iconSize,height:iconSize,color:'#17A2B8'}}/> Un Computador de escritorio o portátil con (cámara, micrófono y parlante)
                        / Un Teléfono Móvil con capacidad de conexión a internet, y sus componentes
                        electrónicos funcionales (cámara, micrófono y parlante)
                    </div>
                    <div>
                        <IcoHead style={{width:iconSize,height:iconSize,color:'#212529'}}/> Es preferible que cuente con auriculares no es necesario sin embargo
                        se recomienda para mejorar la experiencia.
                    </div>
                </div>
                <div className={'lnd-req-image'}>
                    <img src={imgAcceso}/>
                </div>
            </div>
    );
}

export default LandingAccesoServicios;
