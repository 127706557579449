export type User = {
    id: number;
    auth0Id: string;
    userName: string;
    firstName: string;
    middleName: string;
    firstLastName: string;
    secondLastName: string;
    email:string;
    urlProfilePicture: string;
    useGravatar:boolean;
    role:string;
    created:Date;
    updated:Date;
    deleted:Date;
    timeZone:string;
    nationality:string;
    dateOfBirth:string;
}

export const emptyUser:User = {
    id:0,
    auth0Id: '',
    userName: '',
    firstName: '',
    middleName: '',
    firstLastName: '',
    secondLastName: '',
    email:'',
    urlProfilePicture: '',
    useGravatar:false,
    role:'',
    created:new Date(),
    updated:new Date(),
    deleted:new Date(),
    timeZone:'',
    nationality:'',
    dateOfBirth:''
}

export const countries = [
    {code:"BD", name: "Bangladesh"},
    {code:"BE", name: "Belgium"},
    {code:"BF", name: "Burkina Faso"},
    {code:"BG", name: "Bulgaria"},
    {code:"BA", name: "Bosnia and Herzegovina"},
    {code:"BB", name: "Barbados"},
    {code:"WF", name: "Wallis and Futuna"},
    {code:"BL", name: "Saint Barthelemy"},
    {code:"BM", name: "Bermuda"},
    {code:"BN", name: "Brunei"},
    {code:"BO", name: "Bolivia"},
    {code:"BH", name: "Bahrain"},
    {code:"BI", name: "Burundi"},
    {code:"BJ", name: "Benin"},
    {code:"BT", name: "Bhutan"},
    {code:"JM", name: "Jamaica"},
    {code:"BV", name: "Bouvet Island"},
    {code:"BW", name: "Botswana"},
    {code:"WS", name: "Samoa"},
    {code:"BQ", name: "Bonaire, Saint Eustatius and Saba "},
    {code:"BR", name: "Brazil"},
    {code:"BS", name: "Bahamas"},
    {code:"JE", name: "Jersey"},
    {code:"BY", name: "Belarus"},
    {code:"BZ", name: "Belize"},
    {code:"RU", name: "Russia"},
    {code:"RW", name: "Rwanda"},
    {code:"RS", name: "Serbia"},
    {code:"TL", name: "East Timor"},
    {code:"RE", name: "Reunion"},
    {code:"TM", name: "Turkmenistan"},
    {code:"TJ", name: "Tajikistan"},
    {code:"RO", name: "Romania"},
    {code:"TK", name: "Tokelau"},
    {code:"GW", name: "Guinea-Bissau"},
    {code:"GU", name: "Guam"},
    {code:"GT", name: "Guatemala"},
    {code:"GS", name: "South Georgia and the South Sandwich Islands"},
    {code:"GR", name: "Greece"},
    {code:"GQ", name: "Equatorial Guinea"},
    {code:"GP", name: "Guadeloupe"},
    {code:"JP", name: "Japan"},
    {code:"GY", name: "Guyana"},
    {code:"GG", name: "Guernsey"},
    {code:"GF", name: "French Guiana"},
    {code:"GE", name: "Georgia"},
    {code:"GD", name: "Grenada"},
    {code:"GB", name: "United Kingdom"},
    {code:"GA", name: "Gabon"},
    {code:"SV", name: "El Salvador"},
    {code:"GN", name: "Guinea"},
    {code:"GM", name: "Gambia"},
    {code:"GL", name: "Greenland"},
    {code:"GI", name: "Gibraltar"},
    {code:"GH", name: "Ghana"},
    {code:"OM", name: "Oman"},
    {code:"TN", name: "Tunisia"},
    {code:"JO", name: "Jordan"},
    {code:"HR", name: "Croatia"},
    {code:"HT", name: "Haiti"},
    {code:"HU", name: "Hungary"},
    {code:"HK", name: "Hong Kong"},
    {code:"HN", name: "Honduras"},
    {code:"HM", name: "Heard Island and McDonald Islands"},
    {code:"VE", name: "Venezuela"},
    {code:"PR", name: "Puerto Rico"},
    {code:"PS", name: "Palestinian Territory"},
    {code:"PW", name: "Palau"},
    {code:"PT", name: "Portugal"},
    {code:"SJ", name: "Svalbard and Jan Mayen"},
    {code:"PY", name: "Paraguay"},
    {code:"IQ", name: "Iraq"},
    {code:"PA", name: "Panama"},
    {code:"PF", name: "French Polynesia"},
    {code:"PG", name: "Papua New Guinea"},
    {code:"PE", name: "Peru"},
    {code:"PK", name: "Pakistan"},
    {code:"PH", name: "Philippines"},
    {code:"PN", name: "Pitcairn"},
    {code:"PL", name: "Poland"},
    {code:"PM", name: "Saint Pierre and Miquelon"},
    {code:"ZM", name: "Zambia"},
    {code:"EH", name: "Western Sahara"},
    {code:"EE", name: "Estonia"},
    {code:"EG", name: "Egypt"},
    {code:"ZA", name: "South Africa"},
    {code:"EC", name: "Ecuador"},
    {code:"IT", name: "Italy"},
    {code:"VN", name: "Vietnam"},
    {code:"SB", name: "Solomon Islands"},
    {code:"ET", name: "Ethiopia"},
    {code:"SO", name: "Somalia"},
    {code:"ZW", name: "Zimbabwe"},
    {code:"SA", name: "Saudi Arabia"},
    {code:"ES", name: "Spain"},
    {code:"ER", name: "Eritrea"},
    {code:"ME", name: "Montenegro"},
    {code:"MD", name: "Moldova"},
    {code:"MG", name: "Madagascar"},
    {code:"MF", name: "Saint Martin"},
    {code:"MA", name: "Morocco"},
    {code:"MC", name: "Monaco"},
    {code:"UZ", name: "Uzbekistan"},
    {code:"MM", name: "Myanmar"},
    {code:"ML", name: "Mali"},
    {code:"MO", name: "Macao"},
    {code:"MN", name: "Mongolia"},
    {code:"MH", name: "Marshall Islands"},
    {code:"MK", name: "Macedonia"},
    {code:"MU", name: "Mauritius"},
    {code:"MT", name: "Malta"},
    {code:"MW", name: "Malawi"},
    {code:"MV", name: "Maldives"},
    {code:"MQ", name: "Martinique"},
    {code:"MP", name: "Northern Mariana Islands"},
    {code:"MS", name: "Montserrat"},
    {code:"MR", name: "Mauritania"},
    {code:"IM", name: "Isle of Man"},
    {code:"UG", name: "Uganda"},
    {code:"TZ", name: "Tanzania"},
    {code:"MY", name: "Malaysia"},
    {code:"MX", name: "Mexico"},
    {code:"IL", name: "Israel"},
    {code:"FR", name: "France"},
    {code:"IO", name: "British Indian Ocean Territory"},
    {code:"SH", name: "Saint Helena"},
    {code:"FI", name: "Finland"},
    {code:"FJ", name: "Fiji"},
    {code:"FK", name: "Falkland Islands"},
    {code:"FM", name: "Micronesia"},
    {code:"FO", name: "Faroe Islands"},
    {code:"NI", name: "Nicaragua"},
    {code:"NL", name: "Netherlands"},
    {code:"NO", name: "Norway"},
    {code:"NA", name: "Namibia"},
    {code:"VU", name: "Vanuatu"},
    {code:"NC", name: "New Caledonia"},
    {code:"NE", name: "Niger"},
    {code:"NF", name: "Norfolk Island"},
    {code:"NG", name: "Nigeria"},
    {code:"NZ", name: "New Zealand"},
    {code:"NP", name: "Nepal"},
    {code:"NR", name: "Nauru"},
    {code:"NU", name: "Niue"},
    {code:"CK", name: "Cook Islands"},
    {code:"XK", name: "Kosovo"},
    {code:"CI", name: "Ivory Coast"},
    {code:"CH", name: "Switzerland"},
    {code:"CO", name: "Colombia"},
    {code:"CN", name: "China"},
    {code:"CM", name: "Cameroon"},
    {code:"CL", name: "Chile"},
    {code:"CC", name: "Cocos Islands"},
    {code:"CA", name: "Canada"},
    {code:"CG", name: "Republic of the Congo"},
    {code:"CF", name: "Central African Republic"},
    {code:"CD", name: "Democratic Republic of the Congo"},
    {code:"CZ", name: "Czech Republic"},
    {code:"CY", name: "Cyprus"},
    {code:"CX", name: "Christmas Island"},
    {code:"CR", name: "Costa Rica"},
    {code:"CW", name: "Curacao"},
    {code:"CV", name: "Cape Verde"},
    {code:"CU", name: "Cuba"},
    {code:"SZ", name: "Swaziland"},
    {code:"SY", name: "Syria"},
    {code:"SX", name: "Sint Maarten"},
    {code:"KG", name: "Kyrgyzstan"},
    {code:"KE", name: "Kenya"},
    {code:"SS", name: "South Sudan"},
    {code:"SR", name: "Suriname"},
    {code:"KI", name: "Kiribati"},
    {code:"KH", name: "Cambodia"},
    {code:"KN", name: "Saint Kitts and Nevis"},
    {code:"KM", name: "Comoros"},
    {code:"ST", name: "Sao Tome and Principe"},
    {code:"SK", name: "Slovakia"},
    {code:"KR", name: "South Korea"},
    {code:"SI", name: "Slovenia"},
    {code:"KP", name: "North Korea"},
    {code:"KW", name: "Kuwait"},
    {code:"SN", name: "Senegal"},
    {code:"SM", name: "San Marino"},
    {code:"SL", name: "Sierra Leone"},
    {code:"SC", name: "Seychelles"},
    {code:"KZ", name: "Kazakhstan"},
    {code:"KY", name: "Cayman Islands"},
    {code:"SG", name: "Singapore"},
    {code:"SE", name: "Sweden"},
    {code:"SD", name: "Sudan"},
    {code:"DO", name: "Dominican Republic"},
    {code:"DM", name: "Dominica"},
    {code:"DJ", name: "Djibouti"},
    {code:"DK", name: "Denmark"},
    {code:"VG", name: "British Virgin Islands"},
    {code:"DE", name: "Germany"},
    {code:"YE", name: "Yemen"},
    {code:"DZ", name: "Algeria"},
    {code:"US", name: "United States"},
    {code:"UY", name: "Uruguay"},
    {code:"YT", name: "Mayotte"},
    {code:"UM", name: "United States Minor Outlying Islands"},
    {code:"LB", name: "Lebanon"},
    {code:"LC", name: "Saint Lucia"},
    {code:"LA", name: "Laos"},
    {code:"TV", name: "Tuvalu"},
    {code:"TW", name: "Taiwan"},
    {code:"TT", name: "Trinidad and Tobago"},
    {code:"TR", name: "Turkey"},
    {code:"LK", name: "Sri Lanka"},
    {code:"LI", name: "Liechtenstein"},
    {code:"LV", name: "Latvia"},
    {code:"TO", name: "Tonga"},
    {code:"LT", name: "Lithuania"},
    {code:"LU", name: "Luxembourg"},
    {code:"LR", name: "Liberia"},
    {code:"LS", name: "Lesotho"},
    {code:"TH", name: "Thailand"},
    {code:"TF", name: "French Southern Territories"},
    {code:"TG", name: "Togo"},
    {code:"TD", name: "Chad"},
    {code:"TC", name: "Turks and Caicos Islands"},
    {code:"LY", name: "Libya"},
    {code:"VA", name: "Vatican"},
    {code:"VC", name: "Saint Vincent and the Grenadines"},
    {code:"AE", name: "United Arab Emirates"},
    {code:"AD", name: "Andorra"},
    {code:"AG", name: "Antigua and Barbuda"},
    {code:"AF", name: "Afghanistan"},
    {code:"AI", name: "Anguilla"},
    {code:"VI", name: "U.S. Virgin Islands"},
    {code:"IS", name: "Iceland"},
    {code:"IR", name: "Iran"},
    {code:"AM", name: "Armenia"},
    {code:"AL", name: "Albania"},
    {code:"AO", name: "Angola"},
    {code:"AQ", name: "Antarctica"},
    {code:"AS", name: "American Samoa"},
    {code:"AR", name: "Argentina"},
    {code:"AU", name: "Australia"},
    {code:"AT", name: "Austria"},
    {code:"AW", name: "Aruba"},
    {code:"IN", name: "India"},
    {code:"AX", name: "Aland Islands"},
    {code:"AZ", name: "Azerbaijan"},
    {code:"IE", name: "Ireland"},
    {code:"ID", name: "Indonesia"},
    {code:"UA", name: "Ukraine"},
    {code:"QA", name: "Qatar"},
    {code:"MZ", name: "Mozambique"}
]

export type Category = {
    id:number;
    description:string;
    created:Date;
    updated:Date;
    deleted:Date;
}

export type Language = {
    id:number;
    name:string;
    shortHand:string;
    created:Date;
    updated:Date;
    deleted:Date;
}

export type Link={
    id: number|null;
    title: string;
    url: string;
    therapist: Therapist;
    created:Date;
    updated:Date;
    deleted:Date;
}

export type Speciality={
    id:number;
    description:string;
    created:Date;
    updated:Date;
    deleted:Date;
}

export type Therapist = {
    id: number;
    introduction:string;
    biography:string;
    youTubeUrl:string;
    user:User;
    categories:Category[];
    specialities:Speciality[];
    languages:Language[];
    links:Link[];
    therapistType:number;
}

export const emptyTherapist:Therapist = {
    id:0,
    introduction:'',
    biography:'',
    youTubeUrl:'',
    user: emptyUser,
    categories:[] as Category[],
    specialities:[] as Speciality[],
    languages:[] as Language[],
    links:[] as Link[],
    therapistType:1
}

export type TherapistType = {
    id:number;
    name:string;
    created:Date;
    updated:Date;
    deleted:Date;
}
