import React, {ChangeEvent, useContext, useEffect, useState, MouseEvent} from 'react';
import './catalogs.css';
import {useAuth0} from "@auth0/auth0-react";
import {AppContext} from "../../../App";
import axios from "axios";
import {LoadCategories, LoadLanguages, LoadSpecialities} from "../../Utils/utilities";
import SharedToast from "../../Shared/SharedToast/shared.toast";

export type CatalogItemOptions = {id:number,description:string};
export type TherapistTypeOption = {id:number, name:string};
export enum Mode {
    Idle,
    New,
    Editing
}

export type CatalogState = {
    therapistType:number;
    catalogType:number;
    catalogItem:number;
}

const initialCatalogState:CatalogState = {
    therapistType:1,
    catalogType:1,
    catalogItem:1
}

function Catalogs() {
    const [catalogState, setCatalogState] = useState(initialCatalogState);
    const [catalogOptions, setCatalogOptions] = useState([] as CatalogItemOptions[]);
    const [therapistyTypeOptions, setTherapistTypeOptions] = useState([] as TherapistTypeOption[]);
    const [editMode,setEditMode] = useState(Mode.Idle);
    const [editItem,setEditItem] = useState({id:0, description:''});
    const {getAccessTokenSilently} = useAuth0();
    const {state:appState} = useContext(AppContext);
    const {backendurl} = appState;
    const [toastData, setToastData] = useState({
                                                   showSuccess:false,
                                                   successMessage:'',
                                                   showError:false,
                                                   errorMessage:''});

    const loadTherapistTypes = async ()=>{
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${backendurl}/therapy/types`,
                                         {
                                             headers:{
                                                 Authorization:`Bearer ${token}`
                                             }
                                         });
        const types = response.data as {id:number, name:string}[];
        setTherapistTypeOptions(types);
    }

    const loadCategories = async (catalogType:number)=>{
        const cats = await LoadCategories(backendurl,catalogState.therapistType);
        setCatalogOptions(cats);
        setCatalogState({...catalogState,catalogType, catalogItem:cats.length>0?cats[0].id:0})
    }

    const loadSpecialties = async (catalogType:number)=>{
        const specs = await LoadSpecialities(backendurl,catalogState.therapistType);
        setCatalogOptions(specs);
        setCatalogState({...catalogState,catalogType, catalogItem:specs.length>0?specs[0].id:0})
    }

    const loadLanguages = async (catalogType:number)=>{
        const langs = (await LoadLanguages(backendurl)).map(item=> {return {id:item.id, description:item.name};});
        setCatalogOptions(langs);
        setCatalogState({...catalogState,catalogType, catalogItem:langs.length>0?langs[0].id:0});
    }

    const onChange = async (event:ChangeEvent<HTMLSelectElement|HTMLInputElement>)=>{
        if(event.target.id==='catalog-type'){
            switch (parseInt(event.target.value)){
                case 1:
                    await loadCategories(parseInt(event.target.value));
                    break;
                case 2:
                    await loadSpecialties(parseInt(event.target.value));
                    break;
                case 3:
                    await loadLanguages(parseInt(event.target.value));
                    break;
            }
            setEditMode(Mode.Idle);
        }

        if(event.target && event.target.id==='therapist-type'){
            setCatalogState({...catalogState,therapistType:parseInt(event.target.value)});
            setEditMode(Mode.Idle);
        }

        if(event.target && event.target.id==='itemDsc'){
            setEditItem({...editItem, description: event.target.value});
        }

        if(event.target && event.target.id==='catalog-option'){
            console.log(event.target.value);
            setCatalogState({...catalogState, catalogItem:parseInt(event.target.value)})
        }
    }

    const guardar = async () => {
        try{
            const token = await getAccessTokenSilently();

            let url = '';
            const payload = {...editItem, therapistType:catalogState.therapistType};
            const config = {
                headers:{
                    Authorization:`Bearer ${token}`
                }
            };

            switch (catalogState.catalogType){
                case 1:
                    url = `${backendurl}/category`
                    break;
                case 2:
                    url = `${backendurl}/speciality`
                    break;
                case 3:
                    url = `${backendurl}/language`
                    break;
            }

            let response = null;
            switch (editMode){
                case Mode.New:
                    response = await axios.post(url,payload,config);
                    setToastData({...toastData,showSuccess: true, successMessage: 'Registro creado'});
                    break;
                case Mode.Editing:
                    response = await axios.put(url,payload,config);
                    setToastData({...toastData,showSuccess: true, successMessage: 'Registro actualizado'});
                    break;
            }

            switch (catalogState.catalogType){
                case 1:
                    await loadCategories(catalogState.catalogType);
                    break;
                case 2:
                    await loadSpecialties(catalogState.catalogType);
                    break;
                case 3:
                    await loadLanguages(catalogState.catalogType);
                    break;
            }

            setEditMode(Mode.Idle);


        }catch (e){
            setToastData({...toastData,showError: true, errorMessage: 'Error al guardar cambios'});
        }
    }

    const onButtonClickedHandler = async (event:MouseEvent<HTMLButtonElement>)=>{
        switch (event.currentTarget.id){
            case 'btnNuevo':
                setEditItem({id:0,description: ''})
                setEditMode(Mode.New);
                break;
            case 'btnEditar':
                if(catalogOptions.length>0){
                    const item = catalogOptions.filter(i=>{
                        if(i.id===catalogState.catalogItem){
                            return i;
                        }
                    });
                    if(item.length>0){
                        setEditItem({
                                        id:item[0].id,
                                        description: item[0].description
                                    });
                        setEditMode(Mode.Editing);
                    }
                }
                break;
            case 'btnGuardar':
                await guardar();
                break;
            case 'btnCancelar':
                setEditMode(Mode.Idle);
                break;
            case 'btnEliminar':
                break;
        }
    }

    useEffect(()=>{
        loadTherapistTypes().then(r=>{});
        loadCategories(1).then(r=>{});
    },[]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        switch (catalogState.catalogType){
            case 1:
                loadCategories(catalogState.catalogType).then(r=>{});
                break;
            case 2:
                loadSpecialties(catalogState.catalogType).then(r=>{});
                break;
            case 3:
                loadLanguages(catalogState.catalogType).then(r=>{});
                break;
        }
    },[catalogState.therapistType]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleSuccessToastClose = ()=>{
        setToastData({...toastData,showSuccess: false, successMessage: ''});
    }

    const handleErrorToastClose = ()=>{
        setToastData({...toastData,showError: false, errorMessage: ''});
    }

    return (
        <div className={'catalogs-container'}>
            <label htmlFor={'therapist-type'} className={'catalog-label'}>Tipo Proveedor</label>
            <select id={'therapist-type'} name={'therapist-type'}
                    className={'catalog-input'}
                    value={catalogState.therapistType}
                    onChange={onChange}
            >
                {therapistyTypeOptions.map(item=>{
                    return <option value={item.id} key={`tt${item.id}`}>{item.name}</option>
                })}
            </select>
            <label htmlFor={'catalog-type'} className={'catalog-label'}>Tipo Catalogo</label>
            <select id={'catalog-type'} name={'catalog-type'}
                    className={'catalog-input'}
                    value={catalogState.catalogType}
                    onChange={onChange}
            >
                <option value={1}>Categorias</option>
                <option value={2}>Especialidades</option>
                <option value={3}>Lenguajes</option>
            </select>
            <label htmlFor={'catalog-option'} className={'catalog-label'}>Opción</label>
            <select id={'catalog-option'} name={'catalog-option'}
                    className={'catalog-input'}
                    value={catalogState.catalogItem}
                    onChange={onChange}
            >
                {catalogOptions.map(item=>{
                    return <option value={item.id} key={item.id}>{item.description}</option>
                })}
            </select>
            <div className={'catalog-button-bar'}>
                <button id={'btnNuevo'} className={'btn-nuevo'} onClick={onButtonClickedHandler}>Nuevo</button>
                <button id={'btnEditar'} className={'btn-editar'} onClick={onButtonClickedHandler}>Editar</button>
                <button id={'btnEliminar'} className={'btn-eliminar'} onClick={onButtonClickedHandler}>Eliminar</button>
            </div>
            {(editMode === Mode.New || editMode === Mode.Editing) &&
            <div className={'catalog-edit-form'}>
                <label htmlFor={'itemDsc'}>Descripción:</label>
                <input id={'itemDsc'} name={'itemDsc'} value={editItem.description} onChange={onChange} />
                <div>
                    <button id={'btnGuardar'} className={'btn-guardar'} onClick={onButtonClickedHandler}>Guardar</button>
                    <button id={'btnCancelar'} className={'btn-cancelar'} onClick={onButtonClickedHandler}>Cancelar</button>
                </div>
            </div>
            }
            <SharedToast
                handleClose={handleSuccessToastClose}
                show={toastData.showSuccess}
                message={toastData.successMessage}
                variant={'success'}
            />
            <SharedToast
                handleClose={handleErrorToastClose}
                show={toastData.showError}
                message={toastData.errorMessage}
                variant={'error'}
            />
        </div>
    );
}

export default Catalogs;
