import React, {useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import Loading from "react-loading";
import {Link} from "react-router-dom";

const Login = () => {
    const {isLoading, error, loginWithRedirect} = useAuth0();
    let content = (<div></div>);

    if(error)
        content = (
            <div>
                <h1>Error:{error.message}</h1>
            </div>
        );

    if(isLoading)
        content = (
            <div>
                <h1>Login in...</h1>
                <Loading/>
            </div>
        );

    useEffect(()=>{
        const doLogin = async () =>{
            await loginWithRedirect({redirectUri:`${window.location.origin}/protected`, ui_locales:'es en'})
        }
        doLogin();
    },[])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {content}
            <Link to={'/'}>Regresar Inicio</Link>
        </div>
    );
}

export default Login;
