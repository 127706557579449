import React, {useContext, useEffect, useReducer, useState} from 'react';
import './my.profile.css';
import PictureUpdater from "./PictureUpdater/picture.updater";
import {AppContext} from "../../../App";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";
import {emptyUser, countries, emptyTherapist, Category, Therapist, Speciality, Language, Link} from '../../Utils/types';
import {Button, Image, Tabs, Tab, Form, Container, Row, Col} from "react-bootstrap";
import {MyProfileReducer} from "./my.profile.reducer";
import DatePicker from 'react-datepicker';
import Select from "react-select";
import SharedToast from "../../Shared/SharedToast/shared.toast";
import {DateTime} from "luxon";
import DualList, {ListOption} from "../../Shared/DualList/dual.list";
import {LoadCategories, LoadLanguages, LoadSpecialities} from "../../Utils/utilities";
import EditableList from "../../Shared/EditableList/editable.list";

export type ProfileState = {
    showPictureChanger:boolean;
}

const initialState:ProfileState = {
    showPictureChanger:false
}

export type ProfileProps = {
    otherUserId:number|null;
    profileSaved: ()=>void;
}

export const ProfileContext = React.createContext<{
    state:ProfileState;
    dispatch:React.Dispatch<any>;
}>({
    state:initialState,
    dispatch:()=>null});

const MyProfile = (props: ProfileProps) => {
    const {otherUserId, profileSaved} = props;
    const {state} = useContext(AppContext);
    const {getAccessTokenSilently} = useAuth0();
    const {userId, isVerified, backendurl} = state;
    const [user, setUser] = useState(emptyUser);
    const [therapist,setTherapist] = useState(emptyTherapist);
    const [dob,setDOB]=useState(new Date());
    const [profileState, profileDispatch] = useReducer(MyProfileReducer, initialState);
    const [showTherapist, setShowTherapist] = useState(false);
    const [timeZones, setTimeZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState({value:'', label:''});
    const [nations, setNations] = useState([]);
    const [selectedNation, setSelectedNation] = useState({value:'', label:''});
    const [categories, setCategories] = useState([] as ListOption[]);
    const [specialties, setSpecialties] = useState([] as ListOption[]);
    const [languages, setLanguages] = useState([] as ListOption[]);
    const [selectedCategories, setSelectedCategories] = useState([] as ListOption[]);
    const [selectedSpecialities, setSelectedSpecialities] = useState([] as ListOption[]);
    const [selectedLanguages, setSelectedLanguages] = useState([] as ListOption[]);
    const [selectedLinks, setSelectedLinks] = useState([] as Link[]);
    const [toastData, setToastData] = useState({
        showSuccess:false,
        successMessage:'',
        showError:false,
        errorMessage:''});

    const loadUserProfile = async ()=>{
        if(isVerified){
            const token = await getAccessTokenSilently();
            const response = await axios.get(`${backendurl}/user/ById/${otherUserId?otherUserId:userId}`,
                                              {
                                                  headers:{
                                                      Authorization:`Bearer ${token}`
                                                  }
                                              });

            const found = countries.filter(country=> country.code === response.data.nationality);
            if(found){
                const userNation = found[0];
                setSelectedNation({value:userNation.code, label:userNation.name});
            }
            setUser(response.data);
            setSelectedZone({value:response.data.timeZone, label:response.data.timeZone});
            if(response.data.dateOfBirth){
                const dob = DateTime.fromFormat(response.data.dateOfBirth, 'yyyy-MM-dd');
                setDOB(dob.toJSDate());
            }

            if(response.data.role==='provider'){
                const respTherapist = await axios.get(`${backendurl}/therapy/therapist/byUser/${otherUserId?otherUserId:userId}`,
                                                 {
                                                     headers:{
                                                         Authorization:`Bearer ${token}`
                                                     }
                                                 });
                const therData = respTherapist.data as Therapist;
                await loadOptions(therData.therapistType);
                setTherapist({...therapist,...therData});
                const selCats = therData.categories.map((cat)=>{
                    return {value:cat.id, label:cat.description} as ListOption;
                })
                const selSpec = therData.specialities.map((spec)=>{
                    return {value:spec.id, label:spec.description} as ListOption;
                })
                const selLang = therData.languages.map((lang)=>{
                    return {value:lang.id, label:lang.name} as ListOption;
                })
                const selLinks = therData.links;
                setSelectedCategories(selCats);
                setSelectedSpecialities(selSpec);
                setSelectedLanguages(selLang);
                setSelectedLinks(selLinks);
                setShowTherapist(true);
            }
        }
    }

    const updateUser = async ()=>{
        if(isVerified){
            const token = await getAccessTokenSilently();
            const response = await axios.put(`${backendurl}/user/update`,
                                             user,
                                             {
                                                 headers:{
                                                     Authorization:`Bearer ${token}`
                                                 }
                                             });
            setUser({...user,...response.data});

            if(showTherapist){
                debugger;
                const respTherapist = await axios.put(`${backendurl}/therapy/therapist`,
                                                      therapist,
                                                      {
                                                          headers:{
                                                              Authorization:`Bearer ${token}`
                                                          }
                                                      });
                setTherapist({...therapist,...respTherapist.data});
            }

            setToastData({...toastData,showSuccess: true, successMessage: 'Usuario actualizado'});
            profileSaved();
        }
    }

    const loadTimeZones = async()=>{
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${backendurl}/user/timezones`,
                                         {
                                             headers:{
                                                 Authorization:`Bearer ${token}`
                                             }
                                         });
        const tz = response.data.map((res:string)=>{
            return {value:res, label:res.replace('_',' ')};
        })
        setTimeZones(tz);
    }

    const loadNations = async()=>{
        const nat = countries.map((country:any)=>{
            return {value:country.code, label:country.name};
        })
        setNations(nat as never[]);
    }

    useEffect(()=>{
        loadTimeZones().then(r=>{});
        loadNations().then(r=>{});
    },[]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        loadUserProfile().then(r=>{});
    },[userId]);// eslint-disable-line react-hooks/exhaustive-deps

    const changePictureClickHandler = ()=>{
        profileDispatch({type:'show-picture-changer', payload:profileState});
    }

    const pictureChanged= (url:string)=>{
        setUser({...user, urlProfilePicture:url});
    }

    const dobChanged=(date:Date)=>{
        console.log(date);
        setDOB(date);
        const dob = DateTime.fromJSDate(date);
        setUser({...user,dateOfBirth:dob.toFormat('yyyy-MM-dd')});
    }

    const handleTimeZoneChanged = (event:any)=>{
        setSelectedZone({value:event.value, label: event.label});
        setUser({...user, timeZone:event.value});
    }

    const handleNationChanged = (event:any)=>{
        setSelectedNation({value:event.value, label: event.label});
        setUser({...user, nationality:event.value});
    }

    const handleInputChange = (event:any)=>{
        const target = event.target;
        const name = target.name;
        setUser({...user,[name]:target.value});
    }

    const handleForm = (event:any)=>{
        event.preventDefault();
        updateUser().then(r=>{});

    }

    const handleSuccessToastClose = ()=>{
        setToastData({...toastData,showSuccess: false, successMessage: ''});
    }

    const handleErrorToastClose = ()=>{
        setToastData({...toastData,showError: false, errorMessage: ''});
    }

    const loadOptions = async (therapistType:number)=>{
        const cats = await LoadCategories(backendurl, therapistType);
        const specs = await LoadSpecialities(backendurl, therapistType);
        const langs = await LoadLanguages(backendurl);
        setCategories(
            cats.map((cat)=> {
                return {value: cat.id, label: cat.description};
            }));
        setSpecialties(
            specs.map((spec)=>{
                return {value: spec.id, label: spec.description};
            })
        );
        setLanguages(
            langs.map((lang)=>{
                return {value: lang.id, label: lang.name};
            })
        );
    }

    const handleCategoriesChanged = (event:ListOption[])=>{
        debugger;
        if(event.length>0){
            const cats = event.map((cat:ListOption)=>{
                return {id:cat.value, description:cat.label} as Category;
            });
            setTherapist({...therapist,categories:cats});
            setSelectedCategories(event);
        }else{
            setTherapist({...therapist,categories:[] as Category[]});
            setSelectedCategories([] as ListOption[])
        }
    }

    const handleSpecialtiesChanged = (event:ListOption[])=>{
        if(event.length>0){
            const specs = event.map((spec:ListOption)=>{
                return {id:spec.value, description:spec.label} as Speciality;
            });
            setTherapist({...therapist,specialities:specs});
            setSelectedSpecialities(event);
        }else{
            setTherapist({...therapist,specialities:[] as Speciality[]});
            setSelectedSpecialities([] as ListOption[]);
        }
    }

    const handleLanguagesChanged = (event:ListOption[])=>{
        if(event.length>0){
            const langs = event.map((lang:ListOption)=>{
                return {id:lang.value, name:lang.label} as Language;
            });
            setTherapist({...therapist,languages:langs});
            setSelectedLanguages(event);
        }else{
            setTherapist({...therapist,languages:[] as Language[]});
            setSelectedLanguages([] as ListOption[]);
        }
    }

    const handleTherapistInputChange = (event:any) =>{
        const target = event.target;
        const name = target.name;
        setTherapist({...therapist,[name]:target.value});
    }

    const handleLinksChanged = (content:Link[])=>{
        setSelectedLinks(content);
        setTherapist({...therapist,links:content});
    }

    return (
        <ProfileContext.Provider value={{state:profileState,dispatch:profileDispatch}}>
            {user &&
                <div className={'my-profile-container'}>
                    <h3>{otherUserId?'Editar Perfil':'Mi Perfil'}</h3>
                    <div className={'my-proffile-picture'}>
                        <Image src={user.urlProfilePicture} rounded fluid/>
                    </div>
                    <Button variant={'primary'} onClick={changePictureClickHandler}>Cambiar Foto</Button>
                    {profileState.showPictureChanger &&
                        <PictureUpdater
                            userId={userId}
                            currentPicture={user.urlProfilePicture}
                            backendUrl={backendurl}
                            OnPictureChange={pictureChanged}
                        />
                    }
                    <Form
                        onSubmit={handleForm}
                        className={'profile-tab-container'}
                    >
                        <Tabs defaultActiveKey={'profile'} id={'user-profile-forms'} >
                            <Tab eventKey={'profile'} title={'Generales'}>
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId={'first-name'}>
                                                <Form.Label>Primer Nombre</Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    defaultValue={user.firstName}
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputChange}
                                                    name={'firstName'}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId={'second-name'}>
                                                <Form.Label>Segundo Nombre</Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    defaultValue={user.middleName}
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputChange}
                                                    name={'middleName'}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId={'first-last-name'}>
                                                <Form.Label>Primer Apellido</Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    defaultValue={user.firstLastName}
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputChange}
                                                    name={'firstLastName'}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId={'second-last-name'}>
                                                <Form.Label>Segundo Apellido</Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    defaultValue={user.secondLastName}
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputChange}
                                                    name={'secondLastName'}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId={'email'}>
                                                <Form.Label>Correo electrónico</Form.Label>
                                                <Form.Control
                                                    type={'email'}
                                                    defaultValue={user.email}
                                                    onChange={handleInputChange}
                                                    onBlur={handleInputChange}
                                                    name={'email'}
                                                />
                                                <Form.Text className={'text-muted'}>
                                                    Este correo se utilizara para la comunicación del sistema, puede
                                                    ser distinto a su usuario para iniciar sesión
                                                </Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId={'email'}>
                                                <Form.Label>Fecha de nacimiento</Form.Label>
                                                <br/>
                                                <DatePicker
                                                    selected={dob}
                                                    onChange={dobChanged}
                                                    onBlur={handleInputChange}
                                                    dateFormat={'dd/MM/yyyy'}
                                                    className={'form-control'}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId={'timezone'}>
                                                <Form.Label>Zona Horaria</Form.Label>
                                                <Select
                                                    options={timeZones}
                                                    selectValue={selectedZone}
                                                    onChange={handleTimeZoneChanged}
                                                    placeholder={'Seleccione una zona horaria'}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId={'nationallity'}>
                                            <Form.Label>Nacionalidad</Form.Label>
                                            <Select
                                                options={nations}
                                                selectValue={selectedNation}
                                                onChange={handleNationChanged}
                                                placeholder={'Seleccione un pais'}
                                            />
                                                {(selectedNation.value !== '') &&
                                                <Image
                                                    className={'profile-flag'}
                                                    src={`https://www.countryflags.io/${selectedNation.value}/flat/64.png`}
                                                />
                                                }
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            {showTherapist &&
                            <Tab eventKey={'terapeuta'} title={'Terapeuta'}>
                                <Container fluid>
                                    <Form.Group controlId={'therapist-introduction'}>
                                        <Form.Label>Introducción</Form.Label>
                                        <Form.Control
                                            as={'textarea'}
                                            rows={3}
                                            defaultValue={therapist.introduction}
                                            onChange={handleTherapistInputChange}
                                            onBlur={handleInputChange}
                                            name={'introduction'}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId={'therapist-biography'}>
                                        <Form.Label>Biografía</Form.Label>
                                        <Form.Control
                                            as={'textarea'}
                                            rows={3}
                                            defaultValue={therapist.biography}
                                            onChange={handleTherapistInputChange}
                                            onBlur={handleInputChange}
                                            name={'biography'}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId={'therapist-youtube'}>
                                        <Form.Label>Enlace de YouTube</Form.Label>
                                        <Form.Control
                                            type={'text'}
                                            defaultValue={therapist.youTubeUrl}
                                            onChange={handleTherapistInputChange}
                                            onBlur={handleInputChange}
                                            name={'youTubeUrl'}
                                        />
                                    </Form.Group>
                                    <h5>Categorías</h5>
                                    <DualList
                                        SourceOptions={categories}
                                        onChange={handleCategoriesChanged}
                                        SelectedValues={selectedCategories}
                                    />
                                    <br/>
                                    <h5>Especialidades</h5>
                                    <DualList
                                        SourceOptions={specialties}
                                        onChange={handleSpecialtiesChanged}
                                        SelectedValues={selectedSpecialities}
                                    />
                                    <br/>
                                    <h5>Idiomas</h5>
                                    <DualList
                                        SourceOptions={languages}
                                        onChange={handleLanguagesChanged}
                                        SelectedValues={selectedLanguages}
                                    />
                                    <h5>Enlaces</h5>
                                    <EditableList
                                        DefaultItems={selectedLinks}
                                        OnChange={handleLinksChanged}
                                    />
                                </Container>
                            </Tab>
                            }
                        </Tabs>
                        <br/>
                        <Button type={'submit'} variant={'success'} >Guardar Cambios</Button>
                    </Form>
                    <SharedToast
                        handleClose={handleSuccessToastClose}
                        show={toastData.showSuccess}
                        message={toastData.successMessage}
                        variant={'success'}
                    />
                    <SharedToast
                        handleClose={handleErrorToastClose}
                        show={toastData.showError}
                        message={toastData.errorMessage}
                        variant={'error'}
                    />
                </div>
            }
        </ProfileContext.Provider>
    );
}

export default MyProfile;
