import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import GridCell from "./GridCell";
import imgS1 from '../../Assets/Servicios1.png';
import imgS2 from '../../Assets/Servicios2.png';
import imgS3 from '../../Assets/Servicios3.png';
import imgS4 from '../../Assets/Servicios4.png';

const LandingNuestroServicvios = (props: any) => {
    return (
        <Container id={'servicios'} fluid style={{paddingLeft:'3rem', paddingRight:'3rem', paddingTop:'1rem'}}>
            <Row>
                <Col>
                    <h2 style={{textAlign:'center'}}>Nuestros Servicios</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={4} className={'gridCol'}>
                    <GridCell title={'Terapia Individual'}>
                        Enfocada en generar un mayor conocimiento sobre ti mismo. Entender tus emociones, conductas y pensamientos. De esta forma mejorar capacidad
                        de tomar decisiones y elevar tu autoestima.
                    </GridCell>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell imgUrl={imgS1}/>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell title={'Terapia de Pareja'}>
                        Ayuda a resolver los conflictos conyugales abordando directamente sus causas pretende detectar dichos problemas prematuramente para poder prevenir en la medida
                        de lo posible futuros conflictos.
                    </GridCell>
                </Col>
            </Row>

            <Row>
                <Col sm={4} className={'gridCol'}>
                    <GridCell imgUrl={imgS2}/>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell title={'Terapia Infantil'}>
                        Busca que el menor alcance un desarrollo emocional óptimo y dentro de los parámetros
                        considerados como normales. Cuando algún factor del carácter o la personalidad del niño le
                        está impidiendo madurar óptimamente.
                    </GridCell>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell imgUrl={imgS3}/>
                </Col>
            </Row>

            <Row>
                <Col sm={4} className={'gridCol'}>
                    <GridCell title={'Terapia para Adolescentes'}>
                        El objetivo de la terapia es ayudar al adolescente a que recupere o restablezca su
                        bienestar en un tiempo breve, así el adolescente será capaz, por sí mismo, de intentar
                        nuevas soluciones a sus problemas.
                    </GridCell>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell imgUrl={imgS4}/>
                </Col>
                <Col sm={4} className={'gridCol'}>
                    <GridCell title={'Life Coaching'}>
                        Busca mejorar la calidad de vida de las personas, para realizarte en tu vida personal
                        y profesional, ayuda para desarrollar planes de acción para alcanzar este objetivo.
                    </GridCell>
                </Col>
            </Row>
        </Container>
    );
}

export default LandingNuestroServicvios;
