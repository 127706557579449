import React, {useContext, useEffect, useState} from 'react';
import './provider.picker.css';
import axios from "axios";
import {AppContext} from "../../../../../App";
import Select from "react-select";
import {PlannerContext} from "../../planner";

const ProviderPicker = (props: any) => {
    const [data, setData] = useState([]); //Local State
    const [pickerState, setPickerState] = useState({
        selected:{value:0, label:''}
                                                   })
    const {state} = useContext(AppContext);
    const {backendurl} = state;
    const [provider, setProvider] = useState({id:0, imgUrl:`https://storage.googleapis.com/media-vhg/profile_placeholder.png`});
    const {state:plannerState, dispatch} = useContext(PlannerContext);
    const {isDisabled,selectedValue} = props;

    const getData = async ()=>{
        const response = await axios.post(`${backendurl}/public/filteredProviderCards`,{
            categoryId:0,
            specialtyId:0,
            languageId:0,
            therapistType:0
        });
        setData(response.data);
    }

    useEffect(()=>{
        const invoke = async ():Promise<void>=>{
            await getData();
        };
        invoke().then(r => {
        });
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(selectedValue !== null && providers && providers.length > 0)
        {
            const found = providers.filter(x=> parseInt(x.value) === selectedValue);
            if(found && found.length>0)
                setPickerState({...pickerState,selected:found[0]});
        }
    },[data]) // eslint-disable-line react-hooks/exhaustive-deps

    const providers = data.map((provider:any)=>{
        const id = provider.id;
        const label = provider.displayName;
        return {value:id,label, imgUrl:`${provider.imgUrl}`};
    });

    const handleProviderChange = (event:any)=>{
        setProvider({id:event.value, imgUrl:event.imgUrl});
        if(props.changeHandler)
            props.changeHandler(event);
        else
            dispatch({type:'update-provider',payload:{...plannerState,providerId:event.value}});
        setPickerState({...pickerState,selected: event});
    }

    if(props.skipAllOption == null || props.skipAllOption === false)
        providers.unshift({value: 0,label:'Todos',imgUrl:`https://storage.googleapis.com/media-vhg/profile_placeholder.png`});

    return (
        <div className={'provider-picker-container'}>
            <Select options={providers}
                    selectValue={pickerState.selected}
                    onChange={handleProviderChange}
                    className={'provider-select'}
                    placeholder={'Seleccione un Terapeuta'}
                    isDisabled={isDisabled}
            />
            {(props.noImage==null || props.noImage===false) &&
                <img alt={'provider-profile'} src={provider.imgUrl} className={'provider-picture'}/>
            }
        </div>
    );
}

export default ProviderPicker;
